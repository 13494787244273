<template>
  <div class="flex flex-col">
    <div class="text-dark-800 flex items-center font-medium text-sm">
      <div class="relative w-auto flex items-center cursor-pointer">
        <input
          type="checkbox"
          v-bind="$attrs"
          class="appearance-none border-transparent bg-transparent h-4 w-4"
          :checked="checked"
          :value="checked"
          @input="(e) => $emit('update:checked', e.target.checked)"
        />

        <span
          @click="checked = !checked"
          class="
            cursor-pointer
            pointer-events-none
            absolute
            top-1/2
            left-1/2
            transform
            w-4
            border border-white
            -translate-x-1/2 -translate-y-1/2
          "
        >
          <img
            v-if="checked"
            src="../../assets/icons/check2-active.svg"
            alt=""
          />
          <img v-else src="../../assets/icons/check2-null.svg" alt="" />
        </span>
      </div>

      <label for="checkbox" class="ml-1"> {{ label }}</label>
    </div>

    <div v-if="error" class="text-xs font-medium text-error ml-1">
      {{ error }}
    </div>
  </div>
</template>
  <script>
export default {
  inheritAttrs: false,
};
</script>
      
  <script setup>
defineProps({
  checked: {
    type: Boolean,
    default: null,
  },
  error: {
    type: [String, Boolean],
    default: false,
  },
  rounded: {
    type: Boolean,
    default: true,
  },
  label: {
    type: String,
    default: "",
  },
});
</script>
  
  <style>
</style>
