<template>
  <div class="w-full grid grid-cols-1 gap-1">
    <div
      :class="`justify-${justify}`"
      id="otp"
      class="otp-input w-full flex text-center"
    >
      <input
        type="password"
        id="password"
        name="password"
        ref="myInput"
        class="hidden-input"
        v-model="final_value"
        autocomplete="on"
        @input="handleWatch"
      />
      <div
        class="w-auto relative"
        id="otp"
        v-for="i in token_length"
        :key="`input-${i}`"
      >
        <input
          :class="`${
            size ? `h-${size} w-${size}` : 'h-10 sm:h-12 w-10 sm:w-12'
          } ${
            error
              ? 'border-error focus:border-error'
              : 'border-dark-300 focus:border-success focus:ring-success'
          }`"
          class="my-2 sm:mx-2 text-center text-primary border font-semibold rounded focus:outline-none"
          v-model="input[i - 1]"
          @paste="handlePaste($event, i - 1)"
          :type="type"
          :name="`input-${i}`"
          pattern="[0-9]"
          ref="inputRefs"
          autocomplete="off"
          @keydown="handleKeyDown($event, i - 1)"
          @input="handleInput($event, i - 1)"
        />

        <!-- :maxlength="1" -->
        <div
          v-if="type === 'password' && i.value"
          :class="[
            size ? `h-${size - 2} w-${size - 2}` : 'h-8  w-8',
            error ? 'text-error' : 'text-dark-600',
          ]"
          class="absolute top-3 left-3 pointer-events-none text-base flex items-center bg-white justify-center"
        >
          <span class="mt-1 mr-1">*</span>
        </div>
      </div>
    </div>
    <div
      v-if="error"
      class="text-left font-medium block w-full text-xs text-error px-2"
    >
      {{ error }}
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import { ref, onMounted, watch } from "vue";

const props = defineProps({
  value: [String, Number],
  justify: {
    type: String,
    default: "around",
  },
  disabled: Boolean,
  placeholder: String,
  token_length: {
    type: [String, Number],
    default: 6,
  },
  text: String,
  type: {
    type: String,
    default: "text",
  },
  size: [String, Number],
  error: {
    type: [String, Boolean],
    default: false,
  },
});

const emit = defineEmits(["input"]);

const input = ref(Array(props.length).fill(""));
const myInput = ref("");
const inputRefs = ref([]);
const final_value = ref("");

const BACKSPACE_KEY = "Backspace";
const ARROWLEFT = "ArrowLeft";
const ARROWRIGHT = "ArrowRight";
const emitChange = () => {
  emit("input", input.value.join(""));
};

watch(final_value, (val) => {
  input.value = val.split("");

  emitChange();
  // if (input.value && !input.value[0].length) {
  //   console.log(input.value, "nn");
  //   input.value = val.split("");
  //   emitChange();
  // }
});

const handleWatch = async (event) => {
  final_value.value = myInput.value.value;
};

onMounted(() => {
  myInput.value.focus();
  myInput.value.select();
  emitChange();
});

const handleKeyDown = (event, index) => {
  const keyPressed = event.key;
  let nextTarget = inputRefs.value[index + 1];
  let prevTarget = inputRefs.value[index - 1];
  let prevValue = input.value[index];
  switch (keyPressed) {
    case BACKSPACE_KEY:
      event.preventDefault();
      if (!prevValue) {
        if (prevTarget) {
          prevTarget.focus();
          prevTarget.select();
        }
      } else {
        // delete entry
        input.value[index] = "";
        emitChange();
      }
      break;
    case ARROWLEFT:
      event.preventDefault();
      if (prevTarget) {
        prevTarget.focus();
        prevTarget.select();
      }
      break;
    case ARROWRIGHT:
      event.preventDefault();
      if (nextTarget) {
        nextTarget.focus();
        nextTarget.select();
      }
      break;
    default:
      break;
  }
};

const handleInput = (event, index) => {
  event.preventDefault();
  let value = event.target.value;

  if (!value) return;
  if (value && value.length > 1) {
    input.value[index] = value.slice(0, 1);
    final_value.value = input.value.join("");
  } else {
    input.value[index] = value;
    final_value.value = input.value.join("");
  }

  emitChange();
  let nextTarget = inputRefs.value[index + 1];
  if (nextTarget) {
    nextTarget.focus();
    nextTarget.select();
  }
};

const handlePaste = (event, index) => {
  event.preventDefault();
  // Get the pasted text from the clipboard
  const pastedText = event.clipboardData.getData("text/plain");
  // start filling from index
  let start = index;
  let newValue = [...input.value.slice(0, start), ...pastedText].slice(
    0,
    props.length
  );
  input.value = newValue;
  final_value.value = input.value.join("");

  // console.log("input.value", input.value);
  emitChange();
};
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
input[type="password"] {
  -webkit-text-security: square;
  /* font-size: 30px; */
}

.hidden-input {
  position: absolute;
  left: -9999px;
  top: -9999px;
  width: 1px;
  height: 1px;
  overflow: hidden;
  opacity: 0;
  z-index: -1;
}
</style>
