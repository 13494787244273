<template>
  <div class="sane-defaults overflow-hidden">
    <!-- <div
      class="absolute left-0 top-0 w-full overflow-hidden"
      style="z-index: -1"
    >
      <img src="./assets/gradients.png" class="w-full max-w-full" />
    </div> -->
    <SiteNav @book="openModal($event)" />
    <Hero @book="openModal($event)" />
    <Audience />
    <HowItWorks @book="openModal($event)" />
    <FreeForever @book="openModal($event)" />
    <RequestDemo />
    <SiteFooter />
    <Teleport to="head">
      <title>Welcome to Eazipay</title>
    </Teleport>

    <SiteModal
      v-if="showModal"
      @close="showModal = false"
      :isBorder="false"
      class="max-w-lg w-full"
    >
      <div class="md:px-7 px-4 py-4">
        <form @submit.prevent="submitForm">
          <div class="text-center mb-2">
            <h3 class="md:text-2xl text-xl mb-2 font-bold">
              {{
                args.subject === "inspect"
                  ? "Book Inspection"
                  : "Request a call"
              }}
            </h3>
          </div>

          <div
            class="flex flex-col md:grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-4 mt-10"
          >
            <div class="flex flex-col w-full gap-1">
              <label style="color: #474747" class="text-sm -mb-5" for="name"
                >Name</label
              >
              <SiteTextInput
                type="text"
                v-model="args.input.fullName"
                required
              />
            </div>

            <div class="flex flex-col w-full gap-1">
              <label style="color: #474747" class="text-sm -mb-5" for="name"
                >Email address</label
              >
              <SiteTextInput type="email" v-model="args.input.email" required />
            </div>

            <div class="flex flex-col w-full gap-1">
              <label style="color: #474747" class="text-sm -mb-5" for="name"
                >Phone Number</label
              >
              <SiteTextInput
                type="Phone Number"
                v-model="args.input.phoneNumber"
                required
              />
            </div>

            <div class="w-full">
              <label style="color: #474747" class="text-sm" for="name"
                >What plot size are you interested in?</label
              >
              <easiSelectInput2
                :options="[
                  // { label: '200 sqm', value: '200sqm' },
                  // { label: '300 sqm', value: '300sqm' },
                  { label: '450 sqm', value: '450sqm' },
                ]"
                :value="args.input.plotSize"
                @update="args.input.plotSize = $event"
                label="Select plot size"
                type="select"
              />
            </div>

            <div class="flex flex-col mt-1">
              <label style="color: #474747" class="text-sm -mb-5" for="name"
                >When will you like to visit?</label
              >
              <datePicker
                :min="today"
                :value="args.input.visitingDate"
                @update="args.input.visitingDate = $event"
              />
            </div>
            <div class="flex flex-col -mt-6 md:mt-1">
              <label
                style="color: #474747"
                class="text-sm -mb-4 md:-mb-5"
                for="name"
                >What time will that be?</label
              >
              <datePicker
                :value="args.input.time"
                @update="args.input.time = $event"
                :useTime="true"
              />
            </div>

            <div class="col-span-2 flex flex-col -mt-4 md:-mt-5">
              <label style="color: #474747" class="text-sm -mb-3" for="name"
                >Message</label
              >
              <easiTextArea
                class="focus:border-primary focus:ring-primary border-dark-200 p-16 bg-white w-full text-info-700 font-medium text-sm leading-2 focus:outline-none border py-3 px-4 block appearance-none rounded-2xl"
                v-model="args.input.message"
              ></easiTextArea>
            </div>
          </div>

          <div class="flex justify-end mt-6 md:mt-4">
            <SiteButton
              class="w-full md:w-64"
              type="submit"
              :is-loading="submitLoading"
            >
              Submit details</SiteButton
            >
          </div>
        </form>
      </div>
    </SiteModal>

    <easiSuccess v-if="updateSuccess" @close="updateSuccess = false">
      <template v-slot:message>
        <span>
          We will reach out to you shortly to <br />
          confirm your request.
        </span>
      </template>

      <template v-slot:action>
        <easiButton @click="updateSuccess = false" class="md:w-64 w-full"
          >Okay</easiButton
        >
      </template>
    </easiSuccess>
  </div>
</template>

<script setup>
import Audience from "./Audience.vue";
import HowItWorks from "./HowItWorks.vue";
import Hero from "./Hero.vue";
import FreeForever from "./FreeForever.vue";
import RequestDemo from "./RequestDemo.vue";
import SiteFooter from "../../components/SiteFooter.vue";
import SiteNav from "../../components/SiteNav.vue";
import SiteModal from "../../components/SiteModal.vue";
import SiteButton from "../../components/SiteButton.vue";
import SiteTextInput from "../../components/SiteTextInput.vue";
import datePicker from "@/components/global/DatePicker.vue";
import { ref } from "vue";

import { useDataStore } from "@/stores/data.js";

import { helperFunctions } from "@/composable/helperFunctions";

const { processDate } = helperFunctions;

const { mutate } = useDataStore();

const updateSuccess = ref(false);
const showModal = ref(false);
const submitLoading = ref(false);
const today = ref("");
today.value = processDate();
const args = ref({
  input: {
    visitingDate: "",
    time: "",
    phoneNumber: "",
    plotSize: "450sqm",
    message: "",
    fullName: "",
    email: "",
  },
  subject: "inspect",
});

const openModal = (e) => {
  args.value.subject = e;
  showModal.value = true;
};

async function submitForm() {
  submitLoading.value = true;
  try {
    const res = await mutate({
      endpoint: "BookInspectionForm",
      data: args.value,
      service: "SETTINGS",
    });
    console.log(res);
    showModal.value = false;
    updateSuccess.value = true;
  } catch (e) {
    console.log(e);
  } finally {
    submitLoading.value = false;
  }
}
</script>

<style lang="scss" src="../../styles/www.scss"></style>
