<template>
  <main>
    <div
      @click.self="clickOutSide"
      style="
        z-index: 300;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgba(0, 19, 9, 0.8);
      "
      class="inset-0 -z-5 w-full overflow-y-auto py-16"
    >
      <div
        class="z-20 md:w-full bg-white max-w-xl w-11/12 mx-auto pb-4 shadow-xs bord md:mt-16"
      >
        <div
          @click="close"
          class="bg-white p-3 w-9 h-9 rounded-full float-right -mt-12 mx-3 cursor-pointer"
        >
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 11L11 1M1 1L11 11"
              stroke="#292A29"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div
          v-if="isHeader"
          class="w-full flex justify-center items-center px-6 pb-2 text-2xl"
        >
          <slot name="header" />
        </div>

        <main>
          <div class="px-0">
            <slot />
          </div>

          <!-- <slot /> -->
        </main>
      </div>
    </div>
  </main>
</template>

<script setup>
import { onUnmounted } from "@vue/runtime-core";

const props = defineProps({
  isHeader: {
    type: Boolean,
    default: true,
  },
  isBorder: {
    type: Boolean,
    default: true,
  },
  apiDashboard: {
    type: Boolean,
    default: false,
  },
  compliance: {
    type: Boolean,
    default: false,
  },
  payroll: {
    type: Boolean,
    default: false,
  },

  lite: {
    type: Boolean,
    default: false,
  },
  rounded: {
    type: String,
    default: "lg",
  },
  color: {
    type: String,
    default: "bg-background-100",
  },
  persistence: {
    type: Boolean,
    default: true,
  },
  showCloseButton: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(["update", "close"]);

function close() {
  emit("close");
}

const clickOutSide = () => {
  if (!props.persistence) {
    close();
  }
};
</script>

<style scoped>
.bord {
  border-radius: 40px !important;
}
</style>
