<template>
  <main>
    <div class="relative mt-1" v-bind="$attrs">
      <div
        class="p-16 bg-white w-full font-medium text-sm leading-2 focus:outline-none border rounded-sm py-3 px-4 block appearance-none rounded-2xl focus:border-boxBorder"
        aria-readonly
        id="grid-state"
        @click.self="isSelect = !isSelect"
        :class="`${value ? '  text-dark-700' : '  text-dark-500'} ${
          isSelect ? 'border-boxBorder' : 'border-dark-200'
        }`"
      >
        {{ value == "" ? "Select" : getLabelFromValue(value) }}

        <ul
          v-show="isSelect"
          class="mt-10 z-10 h-72 overflow-y-scroll w-full absolute top-1 right-0 py-1 shadow-md bg-white shadow-2xl"
        >
          <li
            v-for="option in options"
            :key="option"
            class="cursor-pointer text-sm option-color"
            :class="value == option.value ? ' bg-boxBorder text-white' : ''"
            @click="update(option)"
          >
            <div class="flex items-center p-4">
              <!-- <input
                class="mx-4 my-2"
                @change="update(option)"
                type="radio"
                name=""
                id=""
                :checked="value === option ? true : false"
                :value="option"
                v-model="optionArray"
              /> -->
              <div class="flex items-center gap-4">
                <span
                  class="w-9 h-9 bg-primary inline-flex items-center justify-center rounded-full border border-boxBorder"
                >
                  <span class="text-white text-sm font-medium">{{
                    initials(option.label)
                  }}</span>
                  <!-- <img
                    alt="..."
                    class="w-full rounded-full contain align-middle"
                    :src="bank.logo"
                  /> -->
                </span>
                <span class=""> {{ option.label }}</span>
              </div>
            </div>
            <hr class="text-dividerColor" />
          </li>
        </ul>
      </div>
      <div
        class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-dark-500"
      >
        <svg
          class="fill-current h-4 w-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path
            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
          />
        </svg>
      </div>
    </div>

    <div v-if="error" class="text-xs font-medium text-error -mt-2 mb-2">
      {{ error }}
    </div>

    <div
      @click="isSelect = false"
      :class="!isSelect ? 'pointer-events-none' : ''"
      class="bg-transparent fixed inset-0"
    ></div>
  </main>
</template>

<script setup>
import { ref, reactive, nextTick, watch, computed } from "vue";
const props = defineProps({
  value: {
    default: "",
  },

  error: {
    type: [String, Boolean],
    default: false,
  },
  options: {
    type: Array,
    default() {
      return [];
    },
  },
});

const emit = defineEmits(["update"]);
let optionArray = reactive([]);

const isSelect = ref(false);

// watch(optionArray, (val) => {
//   optionArray = val;
// });
const initials = computed(() => {
  return (name) => {
    const arr = name.split(" ");
    const str = `${arr[0].charAt(0).toUpperCase()}${arr[1]
      .charAt(0)
      .toUpperCase()}`;
    return str;
  };
});

async function update(value) {
  if (optionArray.includes(value.value)) {
    optionArray = [...optionArray.filter((opt) => value.value !== opt)];
  } else {
    optionArray = [];
    optionArray.push(value.value);
    isSelect.value = false;
  }
  await nextTick();

  emit("update", optionArray[0]);
}
function getLabelFromValue(value) {
  if (value) {
    const label = props.options.filter((item) => {
      return item.value === value;
    });

    return label[0].label;
  }
  return "Select";
}
</script>

<style scoped>
.option-color {
  color: #000000;
  opacity: 0.8;
}
</style>
