<template>
  <main class="md:grid grid-cols-2 h-screen overflow-auto">
    <div class="left w-full flex flex-col items-center justify-center">
      <div class="hidden md:flex text-white pt-10 flex-col">
        <div class="relative mx-auto flex flex-col items-center justify-center">
          <img
            class="imgSlider"
            :src="require(`../../assets/img/${currentImage}.svg`)"
          />
          <img
            src="../../assets/img/leftArrow.svg"
            style="position: absolute; bottom: 25px; left: 0px"
          />
          <img
            src="../../assets/img/rightCircle.svg"
            style="position: absolute; top: -37px; right: 0"
          />
        </div>

        <p
          class="text-32 font-bold mb-5 mt-10 leading-10 text-center break-all"
        >
          {{ currentHeader }}
        </p>
        <p class="mx-auto contentWidth">
          {{ currentContent }}
        </p>

        <div class="w-full my-10 flex justify-center">
          <div class="flex">
            <span
              class="h-2 rounded-full mr-2"
              :class="currentIndex === 1 ? 'bg-secondary w-6 ' : 'bg-white w-2'"
            >
            </span>
            <span
              class="h-2 rounded-full mr-2"
              :class="
                currentIndex === 2 ? 'bg-secondary w-6 ' : 'bg-white w-2 '
              "
            >
            </span>
            <span
              class="h-2 rounded-full"
              :class="currentIndex === 3 ? 'bg-secondary w-6 ' : 'bg-white w-2'"
            >
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="right bg-newLimeGreen">
      <slot></slot>
    </div>
  </main>
</template>

<script setup>
import { ref, watch } from "vue";

let currentImage = ref("newImage1");
let currentHeader = ref("Manage Your Employees");
let currentContent = ref(
  "Be a more efficient employer, and manage all your employee personal, professional, and salary information from one place."
);
const currentIndex = ref(1);
const increment = ref(true);
watch(currentIndex, (val) => {
  if (val === 1) {
    currentHeader.value = " Manage Your Employees";
    currentImage.value = "newImage1";
    currentContent.value =
      "Be a more efficient employer, and manage all your employee personal, professional, and salary information from one place.";
  } else if (val === 2) {
    currentHeader.value = " Run Payroll Easily";
    currentImage.value = "newImage2";
    currentContent.value =
      "No more payment errors. Run and automate monthly payroll and compliance in five minutes or less.";
  } else if (val === 3) {
    currentHeader.value = "Get Low-Interest Loans";
    currentImage.value = "newImage3";
    currentContent.value =
      "Finance your payroll and business expenses at competitively low-interest rates.";
  }
});

watch(
  [currentIndex, increment],
  ([newCurrentIndex, newIncrement]) => {
    if (newIncrement) {
      if (newCurrentIndex === 3) {
        increment.value = false;
      } else {
        setTimeout(() => {
          currentIndex.value++;
        }, 1800);
      }
    } else {
      if (newCurrentIndex === 1) {
        increment.value = true;
      } else {
        setTimeout(() => {
          currentIndex.value = 1;
        }, 1800);
      }
    }
  },
  { immediate: true }
);
</script>

<style scoped>
.left {
  background: url("../../assets/img/newBackground.svg") no-repeat center center;
  background-position: center center;
  background-size: cover;
  /* height: 100vh; */
}

.right {
  /* height: 100vh; */
  overflow: auto;
}
.imgSlider {
  height: 446px;
  width: 485px;
}
.contentWidth {
  max-width: 450px;
}
</style>
