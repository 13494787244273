<template>
  <div
    v-bind="$attrs"
    :class="loan ? 'totalPayrollGradient' : 'totalPayrollGradient'"
    class="text-newLimeGreen relative overflow-hidden w-full bg-white border border-white font-bold text-xl text-left px-4 py-10 md:px-6 xl:px-8 rounded-2xl shadow-lg flex flex-col"
  >
    <p
      class="z-10 text-sm mb-1"
      :class="text == 'small' ? 'md:text-xl text-sm' : ''"
      v-if="title"
    >
      {{ title }}
    </p>
    <p class="z-10 md:text-2xl" v-if="amount">
      {{ formatAmount(parseInt(amount)) }}
    </p>
    <p class="z-10 md:text-2xl" v-else>
      {{ formatAmount(parseInt(0)) }}
    </p>
    <!-- <div class="mt-6 mb-8 flex gap-2 items-center z-10">
                <div class="w-20 xl:w-24" v-for="(cash, i) in 3" :key="i">
                  <img src="@/assets/img/cash.png" alt="" />
                </div>
              </div> -->

    <div
      v-if="loan"
      :class="
        showAll
          ? 'w-20 h-20 -bottom-20 right-20'
          : 'md:w-36 w-32 h-32 md:h-36 w-32 h-32 -bottom-20  md:mt-0 md:-bottom-24 right-0 md:right-20'
      "
      style="background-color: #d9ebcd; opacity: 0.5"
      class="rounded-full absolute"
    ></div>
    <div
      v-if="loan"
      :class="
        showAll
          ? 'w-36 h-36 -bottom-24 -right-24'
          : 'md:w-60 w-32 md:h-60 h-32 -bottom-50 -right-16 mt-3 md:mt-0 md:-right-24'
      "
      style="background-color: #11453b; opacity: 0.5"
      class="rounded-full absolute -z-0"
    ></div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import { helperFunctions } from "@/composable/helperFunctions";
const { formatAmount } = helperFunctions;
const props = defineProps({
  text: {
    type: [Boolean, String],
    default: false,
  },
  title: {
    type: [Boolean, String],
    default: false,
  },
  amount: {
    type: [Boolean, Number, String],
    default: 0,
  },
  loan: {
    type: Boolean,
    default: false,
  },
});
</script>

<style scoped>
.totalPayrollGradient {
  background: linear-gradient(180deg, #11453b 0%, #d9ebcd 100%) !important;
  /* background: linear-gradient(78.04deg, #11453b 4.92%, #b4a572 118.69%); */

  /* background: url("../../assets/img/loan-gradient.png");
  background-position: left;
  background-size: cover;
  background-repeat: repeat-x; */
}
.newBackground {
  background-image: url("../../assets/img/newBackground.svg");
  background-repeat: no-repeat;
  background-size: 100%;
}
</style>
