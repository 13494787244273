<template>
  <svg
    width="12"
    height="11"
    viewBox="0 0 12 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.56281 4.14849L9.43768 0.815545H10.6068L7.05653 4.93643L6.89357 5.12558L7.04998 5.32018L11.374 10.7H8.59361L5.67895 7.0782L5.45309 6.79754L5.21795 7.07047L2.09986 10.6896H0.920673L4.72256 6.28197L4.88584 6.09269L4.72911 5.89794L0.626508 0.8H3.41691L6.10181 4.14049L6.32751 4.42129L6.56281 4.14849ZM2.18328 1.23499H1.55678L1.94957 1.72308L8.73368 10.1531L8.82374 10.265H8.9674H9.81841H10.4444L10.0522 9.77707L3.27666 1.34705L3.18659 1.23499H3.04283H2.18328Z"
      stroke="white"
      stroke-width="0.6"
    />
  </svg>
</template>
