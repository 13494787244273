<template>
  <div class="bg-center relative w-full">
    <!-- <div class="absolute"></div> -->
    <div>
      <div class="w-full flex justify-center">
        <div
          class="grid grid-flow-col auto-cols-auto gap-4 items-center z-10 w-full"
        >
          <div class="mx-0 w-11/12 flex rounded-2xl">
            <div class="swiper-container flex-col flex self-center w-full">
              <div class="swiper-wrapper w-full">
                <div
                  v-for="(block, i) in messages"
                  :key="i"
                  class="bg-white rounded-3xl shadow-xl swiper-slide"
                >
                  <blockquote class="w-full text-left">
                    <div class="relative w-full"></div>

                    <div class="relative m-5 p-5">
                      <svg
                        class="absolute left-0 w-6"
                        viewBox="0 0 512 512"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"
                        />
                      </svg>
                      <p class="text-dark-800 text-sm leading-7 px-5">
                        {{ block.text }}
                      </p>
                      <svg
                        class="absolute right-0 w-6 fill-indigo-500"
                        viewBox="0 0 512 512"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M464 32H336c-26.5 0-48 21.5-48 48v128c0 26.5 21.5 48 48 48h80v64c0 35.3-28.7 64-64 64h-8c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h8c88.4 0 160-71.6 160-160V80c0-26.5-21.5-48-48-48zm-288 0H48C21.5 32 0 53.5 0 80v128c0 26.5 21.5 48 48 48h80v64c0 35.3-28.7 64-64 64h-8c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h8c88.4 0 160-71.6 160-160V80c0-26.5-21.5-48-48-48z"
                        />
                      </svg>
                      <div class="text-sm mt-5 mx-5">
                        <p class="font-medium text-dark-800 capitalize">
                          {{ block.name }}
                        </p>
                        <!-- <p class="mt-1 text-dark-800">CEO Of Marketing</p> -->
                      </div>
                    </div>
                  </blockquote>
                </div>
              </div>

              <div class="md:mt-12 mt-8 swiper-pagination"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

const messages = ref([
  {
    name: "Olubunmi Esther",
    text: "Two years ago, I purchased two plots at Lavida Hills and enjoyed over 200% interest. It was almost unbelievable! When I heard that Pertinence was launching an even better project, Lavida Prime at Ikola-Alagbado, I didn't hesitate to invest in a plot. Today, I am enjoying a whopping 50% capital appreciation. Investing in Lavida Prime has been one of the best decisions I've ever made.",
  },
  {
    name: "Chukwudubem Donald",
    text: "At first, I was hesitant when my brother suggested I buy a property at Lavida Prime. But now, I couldn't be happier with my decision. Not only am I a proud landlord at one of Ikola-Alagbado's most incredible estates, but I'm also enjoying a safe and secure environment with impressive capital appreciation.",
  },
  {
    name: "Kayode Aina",
    text: "As a family man, Lavida Prime is an escape from the hustle and bustle of Lagos while still enjoying the feeling of living in Lagos.",
  },
  {
    name: "Friday Eneware",
    text: "I've been in the real estate game for a while, and Lavida Prime is hands down one of the best investments I've made. The development around the corridor and Pertinence commitment to providing a secure living environment is facinating. I highly recommend it to anyone looking to invest in real estate.",
  },
  {
    name: "Attah Williams",
    text: "Having invested in several properties over the years, I can confidently say that Lavida Prime stands out from the rest. Within a few months of buying a plot, I am enjoying high returns on my investment.",
  },
]);
</script>

<style>
.swiper-pagination {
  bottom: 0;
  position: relative;
  width: 100vw;
}

.swiper-container {
  overflow: hidden;
  width: 88vw !important;
  margin-left: -15px;
}
.swiper-pagination-bullet {
  background-color: #ea4e4b;
}
.swiper-pagination-bullet-active {
  background-color: #ea4e4b;
}

@media (max-width: 768px) {
  .swiper-container {
    overflow: hidden;
    width: 100vw !important;
    margin-left: 0;
  }
}
</style>
