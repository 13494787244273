<template>
  <section
    id="gallery-section"
    style="background-color: #f8f8f8"
    class="audience py-12 lg:pt-20 pb-10 relative z-2 mt-6"
  >
    <div>
      <div class="l-container">
        <div class="text-left mb-6 ml-2 md:ml-10 lg:mb-9">
          <p
            style="
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              color: #18191f;
            "
            class="text-summary"
          >
            GALLERY
          </p>
        </div>

        <!-- <div
          class="flex flex-col md:flex-row gap-y-4 items-center gap-x-2 md:gap-x-8 md:justify-between"
        >
          <div
            @click="switchArr('prev')"
            :class="!end ? 'cursor-not-allowed' : 'cursor-pointer'"
            class="hidden md:block"
          >
            <img src="../landing/assets/prev.svg" alt="" />
          </div>
          <transition name="fade" mode="out-in">
            <div
              v-if="!end"
              key="element1"
              class="fade-element grid grid-cols-2 md:grid-cols-2 gap-2 md:gap-6"
            >
              <div
                v-for="i in startArr"
                :key="i"
                class="w-full h-64 md:h-72 md:overflow-hidden trans"
              >
                <img
                  :src="obj[i]"
                  class="w-full h-full object-cover fade-element"
                  alt=""
                />
              </div>
            </div>

            <div
              v-else
              key="element2"
              class="fade-element grid grid-cols-2 md:grid-cols-2 gap-2 md:gap-6"
            >
              <div
                v-for="i in endArr"
                :key="i"
                class="w-full h-64 md:h-72 md:overflow-hidden trans"
              >
                <img
                  :src="obj[i]"
                  class="w-full h-full object-cover fade-element"
                  alt=""
                />
              </div>
            </div>
          </transition>
          <div
            class="hidden md:block"
            @click="switchArr('next')"
            :class="end ? 'cursor-not-allowed' : 'cursor-pointer'"
          >
            <img src="../landing/assets/next.svg" alt="" />
          </div>

        
        </div>

        <div class="labels flex justify-center gap-8 mt-8">
          <div class="fake-radio">
            <label
              @click="switchArr('prev')"
              :class="!end ? 'slide1' : 'slide2'"
              class="radio-btn"
            ></label>
            <label
              @click="switchArr('next')"
              :class="end ? 'slide1' : 'slide2'"
              class="radio-btn"
            ></label>
          </div>
        </div> -->

        <div
          class="flex flex-col md:flex-row gap-y-4 items-center gap-x-2 md:gap-x-8 md:justify-between"
        >
          <div
            @click="switchArr('prev')"
            :class="track === 1 ? 'invisible' : 'cursor-pointer'"
            class="hidden md:block"
          >
            <img src="./assets/prev.svg" alt="" />
          </div>
          <div name="fade" mode="out-in">
            <div
              v-show="track === 1"
              key="element1"
              class="fade-element grid grid-cols-2 md:grid-cols-2 gap-2 md:gap-6"
            >
              <div
                v-for="i in startArr"
                :key="i"
                class="w-full h-64 md:h-72 md:overflow-hidden trans"
              >
                <img
                  :src="obj[i]"
                  class="w-full h-full object-cover fade-element"
                  alt=""
                  loading="eager"
                />
              </div>
            </div>

            <div
              v-show="track == 2"
              key="element1"
              class="fade-element grid grid-cols-2 md:grid-cols-2 gap-2 md:gap-6"
            >
              <div
                v-for="i in middleArr"
                :key="i"
                class="w-full h-64 md:h-72 md:overflow-hidden trans"
              >
                <img
                  :src="obj[i]"
                  class="w-full h-full object-cover fade-element"
                  alt=""
                />
              </div>
            </div>

            <!-- <div
              v-show="track == 3"
              key="element2"
              class="fade-element grid grid-cols-2 md:grid-cols-2 gap-2 md:gap-6"
            >
              <div
                v-for="i in endArr"
                :key="i"
                class="w-full h-64 md:h-72 md:overflow-hidden trans"
              >
                <img
                  :src="obj[i]"
                  class="w-full h-full object-cover fade-element"
                  alt=""
                />
              </div>
            </div> -->
          </div>
          <div
            class="hidden md:block"
            @click="switchArr('next')"
            :class="track === 2 ? 'invisible' : 'cursor-pointer'"
          >
            <img src="./assets/next.svg" alt="" />
          </div>

          <!-- <div class="md:hidden flex w-full justify-around items-center gap-3">
            <div
              @click="switchArr('prev')"
              :class="!end ? 'cursor-not-allowed' : 'cursor-pointer'"
            >
              <img src="../landing/assets/prev.svg" alt="" />
            </div>

            <div
              @click="switchArr('next')"
              :class="end ? 'cursor-not-allowed' : 'cursor-pointer'"
            >
              <img src="../landing/assets/next.svg" alt="" />
            </div>
          </div> -->
        </div>

        <div class="labels flex justify-center gap-8 mt-8">
          <div class="fake-radio">
            <label
              @click="switchArr('prev', 1)"
              :class="track === 1 ? 'slide1' : 'slide2'"
              class="radio-btn"
            ></label>
            <label
              @click="switchArr('next', 2)"
              :class="track === 2 ? 'slide1' : 'slide2'"
              class="radio-btn"
            ></label>
            <!-- <label
              @click="switchArr('next', 3)"
              :class="track === 3 ? 'slide1' : 'slide2'"
              class="radio-btn"
            ></label> -->
            <!-- <label class="radio-btn slide3"></label> -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { onMounted, onUnmounted, ref } from "vue";
import image1 from "../landing/assets/project-cover1.jpeg";
import image2 from "../landing/assets/project-cover2.png";
import image3 from "../landing/assets/project-cover3.png";
import image4 from "../landing/assets/project-cover4.png";
import image5 from "../landing/assets/project-cover5.png";
import image6 from "../landing/assets/project-cover6.png";
import image7 from "../landing/assets/project-cover7.png";
import image8 from "../landing/assets/project-cover8.png";
// import image9 from "../landing/assets/project-cover9.jpg";
// import image10 from "../landing/assets/project-cover10.jpg";
// import image11 from "../landing/assets/project-cover11.jpg";

import { helperFunctions } from "@/composable/helperFunctions";

const { breakPoints } = helperFunctions;

// const obj = {
//   1: "https://drive.google.com/file/d/1SsUz9oeuXM-Ln9BRTT7ZVosq_2pOheEJ/preview",
//   2: "https://drive.google.com/file/d/1y6tWTbXmkAAK6jjRv95oVajfbQVzqFpM/preview",
//   3: "https://drive.google.com/file/d/1V9pBzTAyY0KYtlK7q8tpI-xhHnju1hLw/preview",
//   4: "https://drive.google.com/file/d/1EW9tp152Ufh6U84sdNI7GoRolfh4Yyje/preview",
//   5: "https://drive.google.com/file/d/1rcBD4jJ-AMFBhOgUPAFYQkdq10nR59kE/preview",
//   6: "https://drive.google.com/file/d/1UI_2fQiSYUcyDKGtIKpSgxGceCRyvj0E/preview",
//   7: "https://drive.google.com/file/d/12lOeL1saMc7Vq1DE36Ijy9IeUqRE-MxM/preview",
//   8: "https://drive.google.com/file/d/1vq0dQY2fQZB3zml65rkKnfmi-8pmMf6U/preview",
// };

//const startArr = ref([1, 2, 3, 4]);
// const endArr = ref([5, 6, 7, 8]);
// const end = ref(false);
// const useArr = ref([]);
// useArr.value = startArr.value;
// function switchArr(act) {
//   if (act === "next") {
//     end.value = true;
//     useArr.value = endArr.value;
//   } else {
//     end.value = false;
//     useArr.value = startArr.value;
//   }
// }
// const toggleElement = () => {
//   end.value = !end.value;
// };

const startArr = ref([1, 2, 3, 4]);
const middleArr = ref([5, 6, 7, 8]);
const endArr = ref([]);
const end = ref(false);
const useArr = ref([]);
const track = ref(1);
useArr.value = startArr.value;
function switchArr(act, num = 0) {
  if (num > 0) {
    track.value = num;
    return;
  }
  if (act === "next") {
    track.value++;
  } else {
    track.value--;
    end.value = false;
    useArr.value = startArr.value;
  }
}
const toggleElement = () => {
  end.value = !end.value;
};
const obj = {
  // 1: image9,
  // 2: image10,
  // 3: image11,
  1: image1,
  2: image2,
  3: image3,
  4: image4,
  5: image5,
  6: image6,
  7: image7,
  8: image8,
};

const autoPlayInterval = ref(null);
function autoplayToggle() {
  // Toggle elements every 5 seconds
  if (breakPoints("sm") || breakPoints("xs")) {
    autoPlayInterval.value = setInterval(() => {
      toggleElement();
    }, 5000);
  }
}

onMounted(() => {
  autoplayToggle();
});

onUnmounted(() => {
  clearInterval(autoPlayInterval.value);
});
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: 0.5s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.slide1 {
  background: #ea4e4b;
  width: 16px;
}

.trans {
  transition: 0.24s ease;
}

.slide2,
.slide3 {
  background: #dddedd;
  margin-left: 4px;
}
.audience {
  .bg-image {
    z-index: -1;
  }

  .bg-pattern {
    z-index: -1;
  }

  .heading {
    color: #11453b;
  }

  .cards {
    position: relative;
  }

  .card {
    background: #ffffff;
    box-shadow: 0px 13px 37px rgba(170, 170, 170, 0.21);
    border-radius: 16px;
    padding: 32px 32px;

    .card-icon {
      background: #f0f7eb;
      border-radius: 12px;
      display: grid;
      place-items: center;
      width: 60px;
      height: 60px;
      margin-bottom: 20px;
    }

    h3 {
      color: #11453b;
      font-size: 20px;
      line-height: 24px;
      font-weight: bold;
      margin-bottom: 15px;
    }

    p {
      color: #515251;
      font-size: 14px;
      position: relative;
      display: flex;
      align-items: center;
      gap: 10px;

      &::before {
        content: "";
        width: 6px;
        height: 12px;
        border-right: 2px solid rgba(#11453b, 0.8);
        border-bottom: 2px solid rgba(#11453b, 0.8);
        transform: rotate(40deg);
        margin-right: 5px;
      }

      &::after {
        content: "";
        display: inline-block;
        width: 13px;
        height: 13px;
        position: absolute;
        left: 0;
        z-index: 0;
        background: rgba(#11453b, 0.1);
        border-radius: 1px;
      }

      & + p {
        margin-top: 10px;
      }
    }
  }

  .reach-out {
    a {
      color: #ea4e4b;
    }
  }

  @screen lg {
    .cards {
      display: flex;
      gap: 0px;
      position: relative;
    }

    .card {
      flex: 1;
      background: radial-gradient(
          50% 50% at 50% 50%,
          rgba(217, 235, 205, 0.14) 0%,
          rgba(217, 235, 205, 0.0266) 100%
        ),
        #11453b;
      box-shadow: 0px 13px 20px rgba(23, 23, 23, 0.2),
        -11px 0px 19px rgba(23, 23, 23, 0.3);
      color: white;
      padding: 60px 50px;
      transition: 0.45s cubic-bezier(0.175, 0.585, 0.32, 1.2075);
      height: 280px;

      .card-icon {
        display: none;
      }

      h3 {
        color: white;
        font-size: 26px;
        line-height: 28px;
        margin-bottom: 24px;
      }

      p {
        font-size: 16px;
        line-height: 22px;
        color: white;

        &::after,
        &::before {
          display: none;
        }
      }
    }

    .card-1 {
      padding-right: 80px;
    }

    .card-2 {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 25%;
      width: 50%;
    }

    .card-3 {
      position: relative;
      left: 0;
    }

    .card-1:hover ~ .card-2 {
      left: 45%;
    }

    .card-2:hover {
      left: 10%;

      & ~ .card-3 {
        left: 5%;
      }
    }

    .card-3:hover {
      left: -2%;

      /* & ~ .card-2 {
        left: 27%;
      } */
    }
  }
}
</style>
