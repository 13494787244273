<template>
  <div
    :class="[type == 'text' ? 'shadown-md' : 'border border-dark-50']"
    class="bg-white rounded-xl p-2 flex items-center gap-2 w-full"
  >
    <div
      v-if="image || url"
      class="h-12 w-12 p-2 bg-white flex justify-center items-center rounded-full border border-dark-100"
    >
      <img
        class="w-ful h-full object-cover"
        :src="image ? image : getFileType"
        alt=""
      />
    </div>
    <div class="flex flex-col gap-1 text-sm md:text-base">
      <div class="flex items-center gap-1">
        <span
          v-if="title"
          :class="!url ? 'text-dark-400 text-xs sm:text-sm' : ''"
          class=""
        >
          {{ title }}
        </span>
        <span
          v-if="tag && url"
          style="background-color: #2196531a"
          class="text-success text-xs rounded p-1"
          >{{ tag }}</span
        >
      </div>

      <span v-if="!url || value">{{ value }}</span>

      <span v-if="fileSize" class="text-dark-400 text-xs">{{ fileSize }}</span>
    </div>
  </div>
</template>

<script setup>
import pdf from "@/assets/icons/pdf-icon.svg";
import jpg from "@/assets/icons/img-icon.svg";
import doc from "@/assets/icons/microsoft-word.svg";
import csv from "@/assets/img/csv.png";

import { computed, onMounted, ref, watch } from "vue";

const props = defineProps({
  title: {
    type: String,
    default: "",
  },
  type: {
    type: String,
    default: "",
  },
  value: {
    type: String,
    default: "N/A",
  },
  image: {
    type: String,
    default: "",
  },
  url: {
    type: String,
    default: "",
  },
  tag: {
    type: String,
    default: "",
  },
});

const watchUrl = computed(() => props.url);

const fileSize = ref(null);

const getFileType = computed(() => {
  if (props.url) {
    const obj = {
      pdf: pdf,
      jpg: jpg,
      jpeg: jpg,
      svg: jpg,
      png: jpg,
      doc: doc,
      csv: csv,
      xlsx: csv,
    };

    let fileType = props.url.split(/[#?]/)[0].split(".").pop().trim();
    return fileType ? obj[fileType] : jpg;
  }
});

function getImageSize() {
  if (props.url) {
    const xhr = new XMLHttpRequest();
    xhr.open("HEAD", props.url, true);

    xhr.onload = () => {
      if (xhr.status === 200) {
        const contentLength = xhr.getResponseHeader("content-length");
        const fileSizeInKiloBytes = contentLength
          ? parseInt(contentLength) / 1024
          : null;

        const fileSizeInMegaBytes = fileSizeInKiloBytes
          ? fileSizeInKiloBytes / 1024
          : null;

        fileSize.value =
          fileSizeInMegaBytes > 1
            ? fileSizeInMegaBytes.toFixed(2) + "MB"
            : fileSizeInKiloBytes.toFixed(2) + "KB";
      } else {
        fileSize.value = null;
      }
    };

    xhr.send();
  }
}

watch(watchUrl, () => {
  getImageSize();
});

onMounted(() => {
  getImageSize();
});
</script>

<style></style>
