<template>
  <div
    style="z-index: 300"
    class="h-screen w-screen bg-mint flex flex-col items-center justify-center fixed inset-0"
  >
    <div class="flex items-end">
      <div class="w-36">
        <img src="@/assets/img/apiLoader.gif" class="" alt="" />
      </div>
      <div
        style="font-size: 100px; font-weight: bold"
        class="text-primary relative top-6"
      >
        dev
      </div>
    </div>
    <p class="mt-5 text-base font-medium">
      Switching to Eazipay API services...
    </p>
    <easiAlert
      v-if="thanksModal"
      @close="thanksModal = false"
      noIcon
      :showDefaultTop="false"
    >
      <div class="flex justify-center mt-3">
        <span>
          <img src="@/assets/img/success.gif" class="h-16" alt="" />
        </span>
        <!-- <img class="w-32" :src="success" alt="" /> -->
      </div>
      <div class="mx-auto text-center font-bold text-xl mt-4">
        Login succesful!
      </div>
      <p class="text-center mt-2">Switching to Eazipay Api service</p>
    </easiAlert>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";

const thanksModal = ref(false);
</script>

<style>
.linear-activity {
  overflow: hidden;
  width: 100%;
  height: 4px;
}

.indeterminate {
  position: relative;
  width: 100%;
  height: 100%;
}

.indeterminate:before {
  content: "";
  position: absolute;
  height: 100%;
  background-color: #fb6538;
  animation: indeterminate_first 1.5s infinite ease-out;
}

.indeterminate:after {
  content: "";
  position: absolute;
  height: 100%;
  background-color: #fd9374;
  animation: indeterminate_second 1.5s infinite ease-in;
}

@keyframes indeterminate_first {
  0% {
    left: -100%;
    width: 100%;
  }
  100% {
    left: 100%;
    width: 10%;
  }
}

@keyframes indeterminate_second {
  0% {
    left: -150%;
    width: 100%;
  }
  100% {
    left: 100%;
    width: 10%;
  }
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin: 4px 0;
  border: 2px solid #e4572e;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #e4572e transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
