<template>
  <main>
    <div class="relative mt-1" v-bind="$attrs">
      <div
        class="bg-white w-full font-medium text-sm leading-2 focus:outline-none border block appearance-none rounded-2xl focus:border-boxBorder"
        id="grid-state"
        @click.self="isSelect = !isSelect"
        :class="`${optionArray ? '  text-dark-700' : '  text-dark-500'} ${
          isSelect ? 'border-boxBorder' : 'border-dark-200 py-3 px-4'
        }`"
      >
        <form v-if="isSelect" action="" class="relative w-full z-10 top-0">
          <easiTextInput
            v-model="searchQuery"
            @keyup="searchFunction"
            :forSelect="true"
            autofocus
          ></easiTextInput>
        </form>
        <span class="relative w-11/12" @click.self="isSelect = true" v-else>
          {{
            optionArray.length == 0
              ? "Select"
              : formatValue(optionArray)
                  .toString()
                  .replace("[", "")
                  .replace("]", "")
                  .replace(/,(?=[^\s])/g, ", ")
          }}</span
        >

        <ul
          v-show="isSelect"
          class="mt-5 z-20 w-full absolute top-8 right-0 py-1 shadow-md bg-white shadow-2xl h-40 overflow-y-scroll"
        >
          <div
            @click="addAll()"
            class="hover:bg-newLightGreen w-full cursor-pointer flex items-center mb-2 gap-2"
          >
            <input
              :checked="allChecked || allSelected"
              class="mx-4 my-2"
              type="checkbox"
              name=""
              id=""
            />
            <span class="uppercase font-medium text-xs">Select All</span>
          </div>
          <li
            v-for="option in optionData"
            :key="option"
            class="cursor-pointer text-sm text-dark z-5"
            :class="[
              optionArray.includes(option.value) &&
              unSelectable !== option.value
                ? ' bg-none '
                : '',
              unSelectable == option.value
                ? 'text-lighGray cursor-not-allowed'
                : '',
            ]"
            @click="update(option.value)"
          >
            <div class="flex items-center gap-2">
              <input
                :checked="
                  optionArray.includes(option.value) &&
                  unSelectable !== option.value
                "
                class="mx-4 my-2"
                type="checkbox"
                name=""
                id=""
                :value="option"
              />
              <span
                :class="
                  unSelectable == option.value
                    ? 'text-lighGray cursor-not-allowed'
                    : undefined
                "
                class="cursor-pointer"
              >
                {{ option.label }}</span
              >
            </div>
            <hr class="my-2 text-dividerColor" />
          </li>
        </ul>
      </div>
      <div
        v-if="!isSelect"
        @click="isSelect = !isSelect"
        class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-dark-500"
      >
        <svg
          class="fill-current h-4 w-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path
            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
          />
        </svg>
      </div>
    </div>
    <div
      class="bg-background-100 max-w-100"
      :class="optionArray.length ? 'p-2 md:p-6 ' : ''"
    >
      <div
        class="grid grid-cols-2 md:flex flex-wrap gap-2 mt-1 mb-7 justify-center items-center"
      >
        <div
          v-for="opt in optionArray"
          :key="opt"
          :class="[checkValue(opt)]"
          class="flex items-center flex-nowrap justify-around rounded-full px-2 py-1 text-center bg-grayBg text-xs w-32"
        >
          <img
            class="cursor-pointer"
            v-show="selected !== opt"
            @click="update(opt)"
            src="@/assets/icons/remove.svg"
            alt=""
          />
          <img
            class="cursor-pointer"
            v-show="selected === opt"
            @click="update(opt)"
            src="@/assets/icons/removeLight.svg"
            alt=""
          />
          <!-- <i
            @click="update(opt)"
            class="fas fa-minus-circle"
            :class="selected === opt ? 'text-white' : 'text-secondary'"
          ></i> -->
          <span class="cursor-pointer" @click="toggleView(opt)">
            {{ formatString(opt) }}</span
          >
          <i
            :class="
              [
                'paye',
                'pension',
                'hmo',
                'lifeInsurance',
                'lifeAssurance',
                'wht',
                //'nsitf',
                //'itf',
                'gratuity',
                //'nhf',
                'businessExpense',
              ].includes(opt) && opt !== selected
                ? 'block '
                : 'hidden'
            "
            @click="toggleView(opt)"
            class="fas fa-angle-right"
          ></i>
          <i
            :class="
              [
                'paye',
                'pension',
                'hmo',
                'lifeInsurance',
                'lifeAssurance',
                'wht',
                //'nsitf',
                //'itf',
                'gratuity',
                //'nhf',
                'businessExpense',
              ].includes(opt) && opt === selected
                ? 'block '
                : 'hidden'
            "
            @click="toggleView(opt)"
            class="fas fa-angle-down"
          ></i>
        </div>
      </div>
      <div>
        <p
          v-show="
            selected &&
            optionArray &&
            optionArray.length &&
            !['nsitf', 'nhf', 'itf'].includes(selected)
          "
          :class="!selected ? 'hidden' : 'block'"
          class="text-center mt-4 mb-6 text-sm text-dark-800 font-bold"
        >
          Input {{ getLabelFromValue(selected) }} details
        </p>

        <p
          v-show="
            selected &&
            optionArray &&
            optionArray.length &&
            ['nsitf', 'nhf', 'itf'].includes(selected)
          "
          :class="!selected ? 'hidden' : 'block'"
          class="text-center mt-4 mb-6 text-sm text-dark-800 font-bold"
        >
          {{ getLabelFromValue(selected) }} is auto calculated
        </p>

        <p
          v-show="
            selected && optionArray && optionArray.length && selected === 'paye'
          "
          :class="!selected ? 'hidden' : 'block'"
          class="text-center mt-4 mb-6 text-sm text-dark-600 font-bold"
        >
          PAYE is only applicable to salaries of ₦30,000 and above.
        </p>

        <p
          v-show="
            selected && optionArray && optionArray.length && selected === 'wht'
          "
          :class="!selected ? 'hidden' : 'block'"
          class="text-center mt-4 mb-6 text-sm text-dark-600 font-bold"
        >
          WHT is 5% of gross salary, subject to change based on circumstances or
          tax laws, and can be adjusted by the company.
        </p>

        <div
          v-if="selected === 'paye' && optionArray.includes('paye')"
          class="flex flex-col"
        >
          <!-- <label for="paye" class="text-dark-800 text-sm font-medium"
            >PAYE Amount
          </label>
          <easiTextInput
            @input="updateArgs"
            placeholder="PAYE Amount"
            type="number"
            name="paye"
            class="mt-1 mb-5"
            v-model="staffLevel.paye.amount"
            readonly
            required
          ></easiTextInput> -->

          <label class="text-dark-800 text-sm font-medium"
            >State For PAYE</label
          >
          <easiSelectInput2
            required
            class="mt-1 mb-3"
            @update="staffLevel.paye.stateForPaye = $event"
            :value="staffLevel.paye.stateForPaye"
            :options="statesData"
          />

          <!-- <label for="paye" class="text-dark-800 text-sm font-medium"
            >PAYE ID
          </label> -->
          <easiTextInput
            @input="updateArgs"
            placeholder="PAYE ID"
            type="text"
            name="paye"
            class="mt-1 mb-5"
            v-model="staffLevel.paye.taxId"
            required
          ></easiTextInput>
        </div>

        <div
          v-if="selected === 'pension' && optionArray.includes('pension')"
          class="flex flex-col"
        >
          <label for="pension" class="text-dark-800 text-sm font-medium"
            >Select pension partner</label
          >
          <easiProviderSelect
            @change="updateArgs"
            required
            class="mt-1 mb-2"
            @update="staffLevel.pension.provider = $event"
            :value="staffLevel.pension.provider"
            :options="pensionProviders"
          />

          <!-- <label for="pensionId" class="text-dark-800 text-sm font-medium"
            >RSA Pin
          </label> -->
          <easiTextInput
            @input="updateArgs"
            placeholder="Input RSA Pin"
            type="text"
            name="pensionId"
            v-model="staffLevel.pension.employeePensionPin"
            class="mt-1 mb-2"
            required
          ></easiTextInput>

          <form class="flex justify-around mb-2" action="">
            <!-- <div>
              <label class="text-dark-800 font-normal text-xs mr-2" for="amount"
                >Amount</label
              >
              <input
                type="radio"
                :value="true"
                v-model="staffLevel.pension.manually_inputted"
              />
            </div> -->

            <div>
              <label
                class="text-dark-800 font-normal text-xs mx-2"
                for="percentage"
                >Voluntary Employer/Employee Contribution</label
              >
              <input
                type="checkbox"
                @change="togglePensionOption($event)"
                v-model="staffLevel.pension.manually_inputted"
              />
            </div>
          </form>

          <!-- <div v-show="staffLevel.pension.manually_inputted">
            <easiTextInput
              @input="updateArgs"
              placeholder="Input Amount"
              type="text"
              :format="true"
              name="amount"
              class="mt-1 mb-3"
              v-model="staffLevel.pension.amount"
              required
            ></easiTextInput>
          </div> -->
          <div v-show="staffLevel.pension.manually_inputted">
            <easiTextInput
              v-show="staffLevel.pension.manually_inputted"
              @input="updateArgs"
              placeholder="Input Employee contribution"
              label="Input Employee contribution"
              type="text"
              :format="true"
              name="employeecontribution"
              class="mt-1 mb-5"
              v-model="staffLevel.pension.employeePensionContribution"
            ></easiTextInput>
          </div>

          <div v-show="staffLevel.pension.manually_inputted">
            <!-- <label
              v-show="staffLevel.pension.manually_inputted"
              for="employeecontribution"
              class="text-dark-800 text-sm font-medium"
              >Voluntary Employer Contribution
            </label> -->
            <easiTextInput
              v-show="staffLevel.pension.manually_inputted"
              @input="updateArgs"
              placeholder="Input Employer contribution"
              label="Input Employer contribution"
              type="text"
              :format="true"
              name="employercontribution"
              class="mt-1 mb-5"
              v-model="staffLevel.pension.employerPensionContribution"
            ></easiTextInput>
          </div>
        </div>

        <div
          v-if="selected === 'hmo' && optionArray.includes('hmo')"
          class="flex flex-col"
        >
          <label for="pension" class="text-dark-800 text-sm font-medium"
            >Select HMO provider</label
          >
          <easiProviderSelect
            @change="updateArgs"
            required
            class="mt-1 mb-3"
            @update="staffLevel.hmo.provider = $event"
            :value="staffLevel.hmo.provider"
            :options="HmoProviders"
          />

          <!-- <label for="duration" class="text-dark-800 text-sm font-medium"
            >Duration
          </label>
          <easiSelectInput2
            @change="updateArgs"
            required
            class="mt-1 mb-3"
            @update="staffLevel.hmo.duration = $event"
            :value="staffLevel.hmo.duration"
            :options="[
              {
                label: 'Weekly',
                value: 'WEEKLY',
              },
              {
                label: 'Monthly',
                value: 'MONTHLY',
              },

              {
                label: 'Annually',
                value: 'ANNUALLY',
              },
            ]"
          /> -->
          <label for="plan" class="text-dark-800 text-sm font-medium"
            >Select Plan
          </label>
          <easiSelectInput2
            @change="updateArgs"
            required
            class="mt-1 mb-3"
            @update="staffLevel.hmo.plan = $event"
            :value="staffLevel.hmo.plan"
            :options="[
              {
                label: 'Bronze',
                value: 'BRONZE',
              },
              {
                label: 'Silver',
                value: 'SILVER',
              },
              {
                label: 'Gold',
                value: 'GOLD',
              },
              {
                label: 'Gold Plus',
                value: 'GOLD_PLUS',
              },
              {
                label: 'Platinum',
                value: 'PLATINUM',
              },
            ]"
          />

          <!-- <label for="hmoamount" class="text-dark-800 text-sm font-medium"
            >Amount
          </label> -->
          <easiTextInput
            @input="updateArgs"
            placeholder="Amount"
            type="text"
            :format="true"
            name="hmoamount"
            v-model="staffLevel.hmo.amount"
            class="mt-1 mb-2"
            required
          ></easiTextInput>
          <p class="text-xs text-center text-secondary my-2">
            Please select if you are charging to employee or employer
          </p>
          <div class="flex justify-around">
            <form class="flex items-center gap-4 justify-around mb-3" action="">
              <div>
                <label
                  class="text-dark-800 font-normal text-xs mr-2"
                  for="charge1"
                  >Charge To Employee</label
                >
                <input
                  type="radio"
                  name="charge"
                  id="charge1"
                  value="EMPLOYEE"
                  v-model="staffLevel.hmo.chargeTo"
                />
              </div>
              <div class="h-4 border bg-none border-gray w-0"></div>

              <div>
                <label
                  class="text-dark-800 font-normal text-xs mx-2"
                  for="charge2"
                  >Charge To Employer</label
                >
                <input
                  type="radio"
                  id="charge2"
                  name="charge"
                  value="EMPLOYER"
                  v-model="staffLevel.hmo.chargeTo"
                />
              </div>
            </form>
          </div>
        </div>

        <div
          v-if="
            selected === 'lifeInsurance' &&
            optionArray.includes('lifeInsurance')
          "
          class="flex flex-col"
        >
          <label
            for="lifeInsurance"
            class="text-dark-800 text-sm font-medium capitalize"
            >Select life Insurance partner</label
          >
          <easiProviderSelect
            @change="updateArgs"
            required
            class="mt-1 mb-3"
            @update="staffLevel.lifeInsurance.provider = $event"
            :value="staffLevel.lifeInsurance.provider"
            :options="lifeInsuranceBodies"
          />

          <label for="duration" class="text-dark-800 text-sm font-medium"
            >Select Duration
          </label>
          <easiSelectInput2
            @change="updateArgs"
            required
            class="mt-1 mb-3"
            @update="staffLevel.lifeInsurance.duration = $event"
            :value="staffLevel.lifeInsurance.duration"
            :options="[
              {
                label: 'Weekly',
                value: 'WEEKLY',
              },
              {
                label: 'Monthly',
                value: 'MONTHLY',
              },

              {
                label: 'Annually',
                value: 'ANNUALLY',
              },
            ]"
          />

          <!-- <label
            for="lifeInsuranceAmount"
            class="text-dark-800 text-sm font-medium"
            >Amount
          </label> -->
          <easiTextInput
            @input="updateArgs"
            placeholder="Amount"
            type="text"
            :format="true"
            name="lifeInsuranceAmount"
            v-model="staffLevel.lifeInsurance.amount"
            class="mt-1 mb-2"
            required
          ></easiTextInput>
        </div>

        <div
          v-if="
            selected === 'lifeAssurance' &&
            optionArray.includes('lifeAssurance')
          "
          class="flex flex-col"
        >
          <label
            for="lifeAssurance"
            class="text-dark-800 text-sm font-medium capitalize"
            >Select life Assurance partner</label
          >
          <easiProviderSelect
            @change="updateArgs"
            required
            class="mt-1 mb-3"
            @update="staffLevel.lifeAssurance.provider = $event"
            :value="staffLevel.lifeAssurance.provider"
            :options="lifeInsuranceBodies"
          />

          <!-- <label for="duration" class="text-dark-800 text-sm font-medium"
            >Select Duration
          </label>
          <easiSelectInput2
            @change="updateArgs"
            required
            class="mt-1 mb-3"
            @update="staffLevel.lifeAssurance.duration = $event"
            :value="staffLevel.lifeAssurance.duration"
            :options="[
              {
                label: 'Weekly',
                value: 'WEEKLY',
              },
              {
                label: 'Monthly',
                value: 'MONTHLY',
              },

              {
                label: 'Annually',
                value: 'ANNUALLY',
              },
            ]"
          /> -->

          <!-- <label
            for="lifeAssuranceAmount"
            class="text-dark-800 text-sm font-medium"
            >Amount
          </label> -->
          <easiTextInput
            @input="updateArgs"
            placeholder="Amount"
            type="text"
            :format="true"
            name="lifeAssuranceAmount"
            v-model="staffLevel.lifeAssurance.amount"
            class="mt-1 mb-2"
            required
          ></easiTextInput>
          <p class="text-xs text-center text-secondary my-2">
            Please select if you are charging to employee or employer
          </p>
          <div class="flex justify-around">
            <form class="flex items-center gap-4 justify-around mb-3" action="">
              <div>
                <label
                  class="text-dark-800 font-normal text-xs mr-2"
                  for="assurance1"
                  >Charge To Employee</label
                >
                <input
                  type="radio"
                  name="assurance"
                  id="assurance1"
                  value="EMPLOYEE"
                  v-model="staffLevel.lifeAssurance.chargeTo"
                />
              </div>
              <div class="h-4 border bg-none border-gray w-0"></div>

              <div>
                <label
                  class="text-dark-800 font-normal text-xs mx-2"
                  for="assurance2"
                  >Charge To Employer</label
                >
                <input
                  type="radio"
                  name="assurance"
                  id="assurance2"
                  value="EMPLOYER"
                  v-model="staffLevel.lifeAssurance.chargeTo"
                />
              </div>
            </form>
          </div>
        </div>

        <!-- <div
          v-if="selected === 'nsitf' && optionArray.includes('nsitf')"
          class="flex flex-col"
        >
          <label for="NSITF" class="text-dark-800 text-sm font-medium"
            >NSITF Amount
          </label>
          <easiTextInput
            @input="updateArgs"
            placeholder="NSITF Amount"
            type="number"
            name="NSITF"
            class="mt-1 mb-5"
            v-model="staffLevel.nsitf.amount"
            readonly
            required
          ></easiTextInput>
        </div> -->

        <!-- <div
          v-if="selected === 'itf' && optionArray.includes('itf')"
          class="flex flex-col"
        >
          <label for="ITF" class="text-dark-800 text-sm font-medium"
            >ITF Amount
          </label>
          <easiTextInput
            @input="updateArgs"
            placeholder="ITF Amount"
            type="number"
            name="ITF"
            class="mt-1 mb-5"
            v-model="staffLevel.itf.amount"
            readonly
            required
          ></easiTextInput>
        </div> -->

        <div
          v-if="selected === 'wht' && optionArray.includes('wht')"
          class="flex flex-col"
        >
          <label
            for="whtPaymentType"
            class="hidden text-dark-800 text-sm font-medium"
            >Select Payment Type(s)
          </label>
          <easiMultiSelectInput
            :showOptions="false"
            @change="updateArgs"
            required
            class="mt-1 mb-3 hidden"
            @update="staffLevel.wht.paymentTypes = $event"
            :value="staffLevel.wht.paymentTypes"
            :options="[
              {
                label: 'Royalties',
                value: 'ROYALTIES',
              },
              {
                label: 'Rent',
                value: 'RENT',
              },
              {
                label: 'Construction',
                value: 'CONSTRUCTION',
              },
              {
                label: 'Hire of Equipment',
                value: 'HIRE_OF_EQUIPMENT',
              },
              {
                label: 'Management Fees',
                value: 'MANAGEMENT_FEES',
              },
              {
                label: 'Directors Fees',
                value: 'DIRECTORS_FEES',
              },
              {
                label: 'Contract other than sales in the ordinary course',
                value: 'OTHERS',
              },
            ]"
          />

          <!-- Change to Either percentage or amount -->
          <!-- <label for="whtPercentage" class="text-dark-800 text-sm font-medium"
            >Percentage
          </label> -->
          <easiTextInput
            @input="updateArgs"
            placeholder="Input Percentage"
            type="number"
            step="any"
            name="whtPercentage"
            class="mt-1 mb-2"
            v-model="staffLevel.wht.percentage"
            required
          ></easiTextInput>
          <p
            v-show="Number(staffLevel.wht.percentage) > 100"
            class="text-error text-xs capitalize"
          >
            percentage value can't be more than 100
          </p>

          <!-- <label for="whtAmount" class="mt-2 text-dark-800 text-sm font-medium"
            >Amount
          </label> -->
          <!-- <easiTextInput
            @input="updateArgs"
            placeholder="Input Amount"
            type="text"
            :format="true"
            name="whtAmount"
            class="hidden mt-1 mb-5"
            v-model="staffLevel.wht.amount"
            required
          ></easiTextInput> -->
        </div>

        <div
          v-if="selected === 'gratuity' && optionArray.includes('gratuity')"
          class="flex flex-col"
        >
          <!-- <label for="lastSalaryDrawn" class="text-dark-800 text-sm font-medium"
            >Last Drawn Salary Amount
          </label> -->
          <easiTextInput
            @input="updateArgs"
            placeholder="Last Drawn Salary Amount"
            type="text"
            :format="true"
            name="lastSalaryDrawn"
            class="mt-1 mb-5"
            v-model="staffLevel.gratuity.salaryAmount"
            required
          ></easiTextInput>

          <!-- <label
            for="numberOfYearsWorked"
            class="text-dark-800 text-sm font-medium"
            >Number of Years Worked
          </label> -->
          <easiTextInput
            @input="updateArgs"
            placeholder="Input Number of Years Worked"
            type="number"
            name="numberOfYearsWorked"
            class="mt-1 mb-5"
            v-model="staffLevel.gratuity.yearsWorked"
            required
          ></easiTextInput>
          <p
            v-show="isNaN(staffLevel.gratuity.yearsWorked)"
            class="text-error text-xs capitalize"
          >
            Please input numbers only
          </p>

          <!-- <label for="gratuityAmount" class="text-dark-800 text-sm font-medium"
            >Gratuity Amount
          </label> -->
          <easiTextInput
            @input="updateArgs"
            placeholder="Input Amount"
            type="text"
            :format="true"
            name="gratuityAmount"
            class="mt-1 mb-5"
            v-model="staffLevel.gratuity.amount"
            required
          ></easiTextInput>
        </div>

        <!-- <div
          v-if="selected === 'nhf' && optionArray.includes('nhf')"
          class="flex flex-col"
        >
          <label for="nhfAmount" class="text-dark-800 text-sm font-medium"
            >NHF Amount
          </label>
          <easiTextInput
            @input="updateArgs"
            placeholder="Input Amount"
            type="number"
            name="NHF"
            class="mt-1 mb-5"
            v-model="staffLevel.nhf.amount"
            readonly
            required
          ></easiTextInput>
        </div> -->

        <div
          v-if="
            selected === 'businessExpense' &&
            optionArray.includes('businessExpense')
          "
          class="flex flex-col"
        >
          <!-- <label for="expenseName" class="text-dark-800 text-sm font-medium"
            >Expense Name
          </label> -->
          <easiTextInput
            @input="updateArgs"
            placeholder="Expense Name"
            type="text"
            name="expenseName"
            v-model="staffLevel.businessExpense.expenseName"
            class="mt-1 mb-2"
            required
          ></easiTextInput>

          <!-- <label for="expenseAmount" class="text-dark-800 text-sm font-medium"
            >Amount
          </label> -->
          <easiTextInput
            @input="updateArgs"
            placeholder="Amount"
            type="text"
            :format="true"
            name="expenseAmount"
            v-model="staffLevel.businessExpense.amount"
            class="mt-1 mb-2"
            required
          ></easiTextInput>
          <p class="text-xs text-center text-secondary my-2">
            Please select if you are charging to employee or employer
          </p>
          <div class="flex justify-around">
            <form class="flex items-center gap-4 justify-around mb-3" action="">
              <div>
                <label
                  class="text-dark-800 font-normal text-xs mr-2"
                  for="employer"
                  >Charge To Employee</label
                >
                <input
                  type="radio"
                  name="employer"
                  id="employer"
                  v-model="providerArgs.chargeExpenseToEmployee"
                  :value="false"
                />
              </div>
              <div class="h-4 border bg-none border-gray w-0"></div>
              <div>
                <label
                  class="text-dark-800 font-normal text-xs mx-2"
                  for="employee"
                  >Charge To Employer</label
                >
                <input
                  type="radio"
                  name="employee"
                  id="employee"
                  v-model="providerArgs.chargeExpenseToEmployee"
                  :value="true"
                />
              </div>
            </form>
          </div>
        </div>

        <easiButton
          class="mt-4"
          type="button"
          v-show="selected && !['nsitf', 'nhf', 'itf'].includes(selected)"
          @click="selected = ''"
          block
          >Submit</easiButton
        >
      </div>
    </div>

    <div v-if="error" class="text-xs font-medium text-error -mt-2 mb-2">
      {{ error }}
    </div>
    <div
      @click="isSelect = false"
      :class="!isSelect ? 'pointer-events-none' : ''"
      class="bg-transparent fixed inset-0"
    ></div>
  </main>
</template>

<script setup>
import pensionProviders from "@/utils/pensionProviders";
import HmoProviders from "@/utils/HmoProviders";
import lifeInsuranceBodies from "@/utils/lifeInsuranceBodies";
import { helperFunctions } from "@/composable/helperFunctions";
import NigerianStates from "@/utils/states";

import { ref, reactive, nextTick, watch, computed, onMounted } from "vue";
import { useDataStore } from "@/stores/data.js";
import { useRoute } from "vue-router";

const { truncateAmount } = helperFunctions;

const store = useDataStore();
const route = useRoute();

const props = defineProps({
  value: {
    default: "",
  },
  grossAmount: {
    type: [Number, String],
    default: 0,
  },
  usePercent: {
    type: Boolean,
    default: true,
  },
  clearObj: {
    type: Boolean,
    default: false,
  },

  error: {
    type: [String, Boolean],
    default: false,
  },
  options: {
    type: Array,
    default() {
      return [];
    },
  },
  validate: {
    type: Boolean,
    default: false,
  },
});
let optionArray = ref([]);
const unSelectable = ref("");
const providerArgs = reactive({
  payeAmount: "",
  pensionProvider: "",
  pensionId: "",
  pensionAmount: "",
  pensionOption: false,
  voluntaryEmployerContribution: 0,
  voluntaryEmployeeContribution: 0,

  hmoProvider: "",
  duration: "",
  hmoAmount: "",
  plan: "",
  chargeHmoToEmployee: false,

  lifeInsuranceAmount: 0,
  lifeInsuranceduration: "",
  lifeInsuranceProvider: "",

  lifeAssuranceAmount: 0,
  lifeAssuranceduration: "",
  lifeAssuranceProvider: "",

  NSITFAmount: 0,

  ITFAmount: 0,

  whtPercentage: 0,
  whtAmount: 0,

  lastSalaryDrawn: 0,
  numberOfYearsWorked: 0,
  gratuityAmount: 0,

  nhfAmount: 0,

  expenseName: "",
  expenseAmount: 0,
  chargeExpenseToEmployee: false,
});

const staffLevel = reactive({
  grossAmount: "0",
  benefits: [],
  wht: {
    amount: "0",
    percentage: "0",
    paymentTypes: [],
  },
  hmo: {
    provider: "",
    amount: "0",
    duration: "MONTHLY",
    plan: "BRONZE",
    chargeTo: "EMPLOYEE",
  },
  lifeInsurance: {
    provider: "",
    amount: "0",
    duration: "MONTHLY",
  },
  lifeAssurance: {
    provider: "",
    amount: "0",
    duration: "MONTHLY",
    chargeTo: "EMPLOYEE",
  },
  itf: {
    amount: "0",
  },
  nsitf: {
    amount: "0",
  },
  pension: {
    amount: "0",
    provider: "",
    employerPensionContribution: "0",
    employeePensionContribution: "0",
    providerPin: "",
    usePercentage: false,
    employeePensionPin: "",
    manually_inputted: false,
  },
  paye: {
    amount: "0",
    stateForPaye: "",
    taxId: "",
  },
  nhf: {
    amount: "0",
  },
  gratuity: {
    salaryAmount: "0",
    yearsWorked: "0",
    amount: "0",
  },
  businessExpense: { amount: 0, expenseName: "" },

  allowanceDetails: [],
  withholdingTax: false,
  usePercentageForAllowance: true,
});

const emit = defineEmits(["update", "args"]);
const statesData = computed(() => {
  let stateArray = [];
  stateArray = NigerianStates.map((state) => {
    return {
      label: state,
      value: state,
    };
  });
  return stateArray;
});

const payeOpt = ref(false);
const optionData = ref([]);
optionData.value = props.options;
const selected = ref("");
const searchQuery = ref("");

const isSelect = ref(false);
const getGross = computed(() => props.grossAmount);
const getOptions = computed(() => props.options);
const getValidate = computed(() => props.validate);
const checkClear = computed(() => props.checkObj);

function getLabelFromValue(value) {
  if (value) {
    const label = props.options.filter((item) => {
      return item.value === value;
    });

    return label[0].label;
  }
}
function formatValue(val) {
  const abbr = ["hmo", "itf", "nsitf", "paye", "nhf", "wht"];

  const newVal = val.map((a) => {
    if (a) {
      if (abbr.includes(a)) {
        return a.toUpperCase();
      }
      return a.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
        return str.toUpperCase();
      });
    }
  });
  return newVal;
}
watch(
  isSelect,
  (val) => {
    console.log(val);
    if (val === true) {
      optionData.value = props.options;
    } else {
      optionData.value = props.options;
    }
  },
  { deep: true }
);
function searchFunction(e) {
  const name = e.target.value;
  let data;
  if (name.length) {
    if (e.key !== "Backspace") {
      data = props.options.filter((x) => {
        return x.label.toLowerCase().indexOf(name.toLowerCase()) > -1
          ? x.label
          : "";
      });
    } else {
      console.log(searchQuery.value);
      data = props.options.filter((x) => {
        return x.label.toLowerCase().indexOf(searchQuery.value.toLowerCase()) >
          -1
          ? x.label
          : "";
      });
    }

    if (data.length) {
      optionData.value = data;
      return data;
    }
  } else {
    optionData.value = props.options;
  }
}
function togglePensionOption(event) {
  staffLevel.pension.manually_inputted = event.target._modelValue;
}
const manual = computed(() => staffLevel.pension.manually_inputted);
watch(manual, (val) => {
  if (val === false && !route.params.id) {
    staffLevel.pension.employeePensionContribution = 0;
    staffLevel.pension.employerPensionContribution = 0;
  }
});
function formatString(strn) {
  console.log("SSTRING", strn);
  const abbr = ["hmo", "itf", "nsitf", "paye", "nhf", "wht"];
  if (strn) {
    if (abbr.includes(strn)) {
      return strn.toUpperCase();
    } else {
      return strn.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
        return str.toUpperCase();
      });
    }
  }
}

const allChecked = ref(false);
const allSelected = ref(false);

function addAll() {
  allChecked.value = !allChecked.value;
  allSelected.value = !allSelected.value;
  optionArray.value.splice(0);
  if (allChecked.value) {
    props.options.forEach((el) => {
      optionArray.value.push(el.value);
    });
  }
  optionArray.value = [...new Set(optionArray.value)];
  // optionData.value = optionArray.value;
  staffLevel.benefits = optionArray.value;
  staffLevel.grossAmount = Number(props.grossAmount);
  staffLevel.usePercentageForAllowance = props.usePercent;

  emit("update", staffLevel);
}

function calculatePercentage(num, per) {
  const result = (Number(num) / 100) * Number(per);
  return truncateAmount(result);
}

function toggleView(option) {
  if (option === selected.value) {
    selected.value = "";
    return true;
  }
  selected.value = option;
}
function update(param) {
  console.log("ACTIVATED");
  selected.value = "";

  if (param && param !== unSelectable.value) {
    if (optionArray.value.includes(param)) {
      // const newArr = [...optionArray.value.filter((opt) => param !== opt)];
      let index = optionArray.value.indexOf(param);
      if (index > -1) {
        optionArray.value.splice(index, 1);
      }
      console.log(param, "Removed From", optionArray);
    } else {
      optionArray.value.push(param);
      nextTick();
    }
    staffLevel.benefits = optionArray.value;
    staffLevel.grossAmount = Number(props.grossAmount);
    staffLevel.usePercentageForAllowance = props.usePercent;

    if (optionArray.value.includes("paye")) {
      unSelectable.value = "wht";
    } else if (optionArray.value.includes("wht")) {
      unSelectable.value = "paye";
    } else {
      unSelectable.value = "";
    }

    emit("update", staffLevel);
  }
}
watch(
  [optionArray.value, getValidate],
  ([newVal, newVal3], [oldVal, oldVal3]) => {
    console.log("RAN ONCE MORE");
    if (props.clearObj === true) newVal.value = [];
    if (newVal) {
      const optVals = [
        "paye",
        "pension",
        "hmo",
        "lifeInsurance",
        "lifeAssurance",
        "wht",
        //"nsitf",
        //"itf",
        "gratuity",
        //"nhf",
        "businessExpense",
      ];
      const itemValue = optVals.find((item) => newVal.includes(item));
      // selected.value = newVal[0];
      selected.value = itemValue;
      // const mapper = optVals.map((item) => {
      //   if (!newVal.includes(item)) {
      //     staffLevel[item].amount = 0;
      //     return false;
      //   } else {
      //     return true;
      //   }
      // });
      // const onePercent = calculatePercentage(getGross.value, 1);
      // const twoPointFive = calculatePercentage(getGross.value, 2.5);
      // if (newVal.includes("nsitf")) {
      //   console.log("Added NSITF", newVal);
      //   staffLevel.nsitf.amount = onePercent;
      // } else {
      //   staffLevel.nsitf.amount = null;
      // }
      // if (newVal.includes("itf")) {
      //   console.log("Added ITF", newVal);
      //   staffLevel.itf.amount = twoPointFive;
      //   console.log(staffLevel.itf.amount);
      // } else {
      //   staffLevel.itf.amount = null;
      // }
      // if (newVal.includes("nhf")) {
      //   console.log("Added NHF", newVal);
      //   staffLevel.nhf.amount = twoPointFive;
      // } else {
      //   staffLevel.nhf.amount = null;
      // }
      // if (newVal.includes("paye")) {
      //   console.log("Added PAYE", newVal);
      //   staffLevel.paye.amount = twoPointFive;
      // } else {
      //   staffLevel.paye.amount = null;
      // }
    }
  },
  { deep: true }
);

function checkValue(option) {
  const arr = [
    //"paye",
    "pension",
    "hmo",
    "lifeInsurance",
    "lifeAssurance",
    "wht",
    //"nsitf",
    //"itf",
    "gratuity",
    //"nhf",
    "businessExpense",
  ];
  if (
    staffLevel[option] &&
    staffLevel[option].amount === 0 &&
    selected.value === option
  ) {
    return "bg-primary text-white border border-primary";
  }
  if (
    staffLevel[option] &&
    staffLevel[option].amount !== 0 &&
    selected.value === option
  ) {
    return "bg-primary opacity-30 text-white border border-primary";
  }
  if (
    staffLevel[option] &&
    staffLevel[option].amount !== 0 &&
    selected.value !== option
  ) {
    return "text-gray border border-gray bg-none";
  }
  if (
    staffLevel[option] &&
    staffLevel[option].amount === 0 &&
    selected.value !== option
  ) {
    return "bg-none text-dark-800 border border-dark-800";
  }
  return "text-gray border border-gray bg-none";
}

// watch(checkClear, (val) => {
//   if (val === true) optionArray.value = [];
// });

function updateArgs() {
  console.log(staffLevel);
  emit("args", staffLevel);
}

if (route.params.id) {
  console.log("RANNN!!!!");
  const employee = computed(() => store.getEmployeeById(route.params.id));
  const staffLevelDetails = employee.value.staffLevel;
  staffLevel.grossAmount = Number(staffLevelDetails.grossAmount);
  const keys = [
    "paye",
    "pension",
    "hmo",
    "lifeInsurance",
    "lifeAssurance",
    "wht",
    "nsitf",
    "itf",
    "gratuity",
    "nhf",
    "businessExpense",
  ];

  const mapValues = keys.map((item) => {
    if (staffLevelDetails[item] && staffLevelDetails[item].amount > 0) {
      update(item);
      staffLevel[item] = staffLevelDetails[item];
      delete staffLevel[item].__typename;
    }
  });
  emit("update", staffLevel);
  console.log(staffLevelDetails, "MAPPED", staffLevel);
}
</script>

<style></style>
