<template>
  <!-- /////// Desktop View ///////// -->
  <div
    class="overflow-auto rounded-t-xl border-t border-outlineGray text-primary hidden md:flex"
  >
    <table class="table-auto w-full overflow-y-auto">
      <thead class="">
        <tr class="text-header font-bold shadow-md bg-background relative">
          <th
            class="bg-background text-left font-bold capitalize text-sm tracking-wide text-header logo text-center whitespace-nowrap p-0 sticky left-0"
          >
            <div>
              <div class="border border-outlineGray px-2 py-3">
                <input type="checkbox" />
              </div>
            </div>
          </th>

          <th
            v-for="(title, index) in headers"
            :key="index"
            class="bg-background text-left font-bold capitalize text-sm tracking-wide text-grey uppercase logo rounded-lg text-center whitespace-nowrap p-0"
          >
            <div class="px-2 py-3 uppercase border border-outlineGray">
              {{ title }}
            </div>
          </th>
        </tr>
      </thead>
      <div class="p-3"></div>

      <!-- //////// when employee is emplty //// -->
      <tbody>
        <tr
          v-for="(item, i) in 4"
          :key="i"
          class="hover:bg-easigrey group capitalize text-tableBpdy cursor-pointer p-1 relative"
        >
          <td
            class="border border-outlineGray text-left text-sm p-2 group-hover:bg-mint group-hover:border-mint sticky left-0"
          >
            <div class="">
              <span class="cursor-pointer">
                <img class="w-4" src="@/assets/icons/check3-null.svg" alt="" />
              </span>
            </div>
          </td>

          <td class="border border-outlineGray text-sm p-1"></td>
          <td class="border border-outlineGray bg-background-200 text-sm p-1">
            <div class="flex items-center gap-2"></div>
          </td>
          <td
            class="border border-outlineGray bg-background-200 text-sm p-1"
          ></td>
          <td class="border border-outlineGray text-sm p-1"></td>
          <td class="border border-outlineGray text-sm p-1"></td>
          <td class="border border-outlineGray text-sm p-1"></td>
          <td class="border border-outlineGray text-sm p-1"></td>
          <td
            class="border border-outlineGray text-left text-sm p-4 font-semibold uppercase"
          ></td>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- /////// Mobile View ///////// -->
  <div class="flex md:hidden w-full">
    <div class="w-full">
      <div :key="i" class="w-full mb-8">
        <table class="table-auto w-full overflow-y-auto">
          <tbody class="font-medium text-sm">
            <tr>
              <td class="w-4/12 border p-4 bg-indigo-100 border-primary">
                <div class="flex items-center gap-2">
                  <input type="checkbox" name="" id="" />
                </div>
              </td>
              <td
                class="w-8/12 border border-outlineGray text-left p-4 px-3 group-hover:bg-indigo-100 group-hover:border-indigo-300"
              ></td>
            </tr>
            <tr>
              <td class="w-4/12 border p-4 bg-indigo-100 border-primary">
                <div class="flex items-center gap-2"></div>
              </td>
              <td
                class="w-8/12 border border-outlineGray text-left p-4 px-3 group-hover:bg-indigo-100 group-hover:border-indigo-300"
              ></td>
            </tr>
            <tr>
              <td class="w-4/12 border p-4 bg-indigo-100 border-primary">
                <div class="flex items-center gap-2"></div>
              </td>
              <td
                class="w-8/12 border border-outlineGray text-left p-4 px-3 group-hover:bg-indigo-100 group-hover:border-indigo-300"
              ></td>
            </tr>

            <tr>
              <td class="w-4/12 border p-4 bg-indigo-100 border-primary">
                <div class="flex items-center gap-2"></div>
              </td>
              <td
                class="w-8/12 border border-outlineGray text-left p-4 px-3 group-hover:bg-indigo-100 group-hover:border-indigo-300"
              ></td>
            </tr>
            <tr>
              <td class="w-4/12 border p-4 bg-indigo-100 border-primary">
                <div class="flex items-center gap-2"></div>
              </td>
              <td
                class="w-8/12 border border-outlineGray text-left p-4 px-3 group-hover:bg-indigo-100 group-hover:border-indigo-300"
              ></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

const headers = ref([
  "Employee Id",
  "First Name",
  "Last Name",
  "Job Title",
  "Department",
  "Employment Type",
  "Net Pay",
  "Status",
]);
</script>

<style></style>
