<template>
  <main>
    <div
      @click.self="close"
      style="
        z-index: 300;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: #032821b9;
      "
      class="inset-0 -z-5 w-full overflow-y-auto py-16"
    >
      <div
        style="border-radius: 40px"
        class="md:max-w-sm z-10 w-11/12 mx-auto bg-white rounded-2xl overflow-y-auto shadow-xs"
      >
        <div class="flex justify-center">
          <img class="w-24 mt-8" :src="success" alt="" />
        </div>

        <div
          v-if="!noHead"
          class="w-full text-primary-deep font-extrabold flex justify-center items-center p-6 pb-2 text-2xl"
        >
          Success!
        </div>

        <main
          :class="noHead ? 'mt-3' : undefined"
          class="flex flex-col justify-center gap-7 mb-7 items-center"
        >
          <div class="px-0 text-sm text-center text-dark-800">
            <slot name="message" />
          </div>

          <div class="w-full flex justify-center">
            <slot name="action" />
          </div>
        </main>
      </div>
    </div>
  </main>
</template>

<script setup>
import success from "@/assets/icons/success.svg";
const emit = defineEmits(["update"]);
const props = defineProps({
  noHead: { type: Boolean, default: false },
});
function close() {
  emit("close");
  window.location.reload();
}
</script>

<style></style>
