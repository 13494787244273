<template>
  <div class="md:w-8/12 w-full mx-auto mt-8 grid grid-cols-1 gap-3">
    <div
      v-for="(faq, i) in visibleData"
      :key="i + 1"
      class="relative mb-1 w-full"
    >
      <input
        type="checkbox"
        :checked="expanded[i + 1]"
        @change="toggleAccordion(i + 1)"
        :id="`toggle${i + 1}`"
        class="toggle hidden"
      />
      <label
        class="title block text-sm text-white font-bold pr-12 md:pr-4 py-4 pl-4 cursor-pointer"
        :for="`toggle${i + 1}`"
        :class="
          expanded[i + 1]
            ? 'rounded-t-3xl backgrd'
            : 'rounded-3xl bg-primary-deep'
        "
      >
        {{ faq.question }}
      </label>

      <div
        v-show="expanded[i + 1]"
        class="content overflow-hidden text-white rounded-b-3xl -mt-4 z-10"
        :class="expanded[i + 1] ? ' backgrd' : 'bg-primary-deep'"
      >
        <p class="p-4 text-xs leading-6">
          {{ faq.answer }}
        </p>
      </div>

      <div
        class="bg-transparent absolute right-1 mt-2 top-4 w-10 pointer-events-none"
      >
        <div v-show="expanded[i + 1]" class="focus:outline-none bg-none">
          <svg
            width="10"
            height="18"
            viewBox="0 0 10 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.25 1.5L8.75 9L1.25 16.5"
              stroke="#E3E5E5"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div v-show="!expanded[i + 1]" class="focus:outline-none bg-none">
          <svg
            width="18"
            height="10"
            viewBox="0 0 18 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.5 1.25L9 8.75L1.5 1.25"
              stroke="#8D8E8D"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
    </div>

    <button
      class="bg-none focus:outline-none text-right text-sm text-white"
      @click="seeMore(faqs.length === visibleData.length)"
    >
      {{ faqs.length === visibleData.length ? "See less" : "See more" }}
    </button>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";

const expanded = ref({
  1: false,
  2: false,
  3: false,
  4: false,
  5: false,
  6: false,
  7: false,
  8: false,
  9: false,
  10: false,
  11: false,
});
const visibleData = ref([]);
const faqs = ref([
  {
    question: "What’s the instalment payment plan?",
    answer: "We have 3 months, 6 months, and 12 months of instalment payment.",
  },
  {
    question: "Would the client be offered instant allocation?",
    answer: "No, allocation is not instant.",
  },
  {
    question: "What development or infrastructure would be provided?",
    answer:
      "Basic infrastructure such as the gatehouse, road networks, drainage, fencing, and electrification.",
  },
  {
    question: "How soon can a client start development?",
    answer: "As soon as the client gets a physical allocation.",
  },
  {
    question: "How soon is the company starting development?",
    answer:
      "The development of the site is continuous. The construction of the gatehouse and perimeter fencing is ongoing.",
  },
  {
    question: "What does the client get after completion of payment?",
    answer: "The client gets his receipts and a contract of sale.",
  },
  {
    question: "What are the additional costs?",
    answer:
      "Once payment of land is complete, the client pays the documentation and developmental levy.",
  },
  {
    question: "Is the property motorable or accessible?",
    answer: "Yes, it is motorable and accessible.",
  },
  {
    question:
      "Can it be used for commercial activities and what are the clauses?",
    answer:
      "Yes, it can be used for commercial purposes. Commercial plots are twice the price for residential plots.",
  },
  {
    question:
      "Is there a restriction regarding the kind of building a client can construct?",
    answer:
      "There is no restriction, but the client's building drawings have to be approved by the company's architect.",
  },
  {
    question: "A proper description of how to get there by bus",
    answer:
      "From Ikeja, take a bus going to Iyana Ipaja or Abule Egba. Take a bus or Keke to Ajasa Command. Take a bike to Alimi Dosunmu Street (Pako Ejigun). Lavida Prime is on your right after the bridge.",
  },
]);
visibleData.value.push(...faqs.value.slice(0, 5));

function seeMore(sz) {
  if (sz) {
    visibleData.value = [];
    visibleData.value.push(...faqs.value.slice(0, 5));
  } else {
    visibleData.value = [];
    visibleData.value = [...faqs.value];
  }
}

const toggleAccordion = (index) => {
  expanded.value[index] = !expanded.value[index];
};

onMounted(() => {
  faqs.value.forEach((fa, i) => {
    expanded.value[i + 1] = false;
  });
});
</script>

<style lang="scss">
// all the properties here should be added into tailwind.config.js

.backgrd {
  background-color: #253932;
}
.title {
  color: #7a7572;
}

// if you need after/before pseudo elements please use https://github.com/croutonn/tailwindcss-pseudo-elements
.title::after,
.title::before {
  //   content: "";
  position: absolute;
  right: 1.25em;
  top: 1.25em;
  width: 2px;
  height: 0.75em;
  background-color: #7a7572;
  transition: all 0.2s;
}

.title::after {
  transform: rotate(90deg);
}

.content {
  max-height: 0;
  transition: max-height 0.2s;
}

.toggle:checked {
  & + .title + .content {
    max-height: 500px;
  }
  & + .title::before {
    transform: rotate(90deg) !important;
  }
}
</style>
