<template>
  <nav class="site-nav py-5 md:py-6">
    <div class="l-container flex items-center">
      <RouterLink to="/" class="nav-logo inline-block mr-44 -ml-4">
        <!-- <Logo /> -->
        <h1 class="text-primary ml-3 md:ml-0 text-2xl md:text-2xl font-bold">
          TopAce
        </h1>
      </RouterLink>
      <div
        class="hidden lg:flex flex-1 justify-center self-center items-center"
      >
        <ul
          class="nav-links flex text-center justify-center text-sm items-center gap-6"
        >
          <li>
            <a class="nav-link" href="#features-section">Features</a>
          </li>
          <li>
            <a class="nav-link" href="#gallery-section">Gallery</a>
          </li>
          <li>
            <a class="nav-link" href="#pricing-section">Pricing</a>
          </li>
          <li>
            <a class="nav-link" href="#footer-section">FAQs</a>
          </li>
          <li>
            <a class="nav-link" href="#footer-section">Contact us</a>
          </li>
        </ul>

        <div class="nav-btns ml-auto -mr-7">
          <button
            @click="$emit('book', 'inspect')"
            class="focus:outline-none register-btn"
          >
            Book Inspection
          </button>
        </div>
      </div>
      <div class="lg:hidden flex flex-1 justify-end">
        <button class="hamburger" @click="openMobileNav">
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>
    </div>

    <div
      class="mobile-nav-overlay fixed left-0 top-0 right-0 bottom-0"
      :class="{ show: show === true }"
      @click.self="closeMobileNav"
    ></div>
    <div class="mobile-nav-menu" :class="{ open: show === true }">
      <div class="px-4 pb-12">
        <div class="flex py-5 md:py-6 items-center justify-between mb-10">
          <h1 class="text-primary text-2xl md:text-2xl font-bold">TopAce</h1>
          <div>
            <button
              class="mobile-menu-close-btn p-2 hover:opacity-60"
              @click="closeMobileNav"
            >
              <CloseIcon />
            </button>
          </div>
        </div>
        <ul class="grid gap-4">
          <li>
            <a @click="closeMobileNav" class="nav-link" href="#features-section"
              >Features</a
            >
          </li>
          <li>
            <a @click="closeMobileNav" class="nav-link" href="#gallery-section"
              >Gallery</a
            >
          </li>
          <li>
            <a @click="closeMobileNav" class="nav-link" href="#pricing-section"
              >Pricing</a
            >
          </li>
          <li>
            <a @click="closeMobileNav" class="nav-link" href="#footer-section"
              >FAQs</a
            >
          </li>
          <li>
            <a @click="closeMobileNav" class="nav-link" href="#footer-section"
              >Contact us</a
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script setup>
import Logo from "./Logo.vue";
import { ref } from "vue";

import CloseIcon from "./icons/Close.vue";

const emit = defineEmits(["book"]);

const show = ref(false);
const isHovered = ref(false);
const closeMobileNav = () => {
  show.value = false;
  // if (event) {
  //   window.location.href = event.currentTarget.href;
  // }
};
const openMobileNav = () => (show.value = true);
const showDiv = () => {
  isHovered.value = true;
};

const hideDiv = () => {
  isHovered.value = false;
};
</script>

<style lang="scss" scoped>
.over {
  box-shadow: 0px 20px 48px 0px #aaaaaa4a;
}
.hamburger {
  display: grid;
  gap: 4px;
  cursor: pointer;

  span {
    display: inline-block;
    height: 2px;
    width: 20px;
    background-color: #14181f;
    border-radius: 2px;
  }
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 35px;
}

.nav-link {
  font-weight: 500;
  transition: 0.2s ease;

  &:hover {
    color: #ea4e4b;
  }

  &.router-link-active {
    color: #ea4e4b;
  }
}

.nav-btns {
  display: flex;
  align-items: center;
  gap: 20px;

  button {
    width: 160px;
    border-radius: 99px;
    padding: 0px;
    height: 40px;
    border-radius: 99px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
  }

  .login-btn {
    border: 1px solid #11453b;
  }
  .register-btn {
    background: #d9ebcd;
    color: #11453b;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
}

.mobile-nav-menu {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  background: white;
  z-index: 20;
  width: min(300px, 80vw);
  transform: translateX(100%);
  transition: 0.24s ease;
  visibility: hidden;

  &.open {
    transform: translateX(0);
    visibility: visible;
  }

  a {
    color: #515251;
    font-size: 16px;
    font-weight: bold;
  }

  a.btn-shadow {
    color: white;
  }
}

.mobile-nav-overlay {
  background: rgb(17, 69, 59, 0.5);
  opacity: 0;
  visibility: hidden;
  opacity: 0.25s ease;
  z-index: 10;

  &.show {
    opacity: 1;
    visibility: visible;
  }
}
</style>
