import { createRouter, createWebHistory } from "vue-router";
import { useDataStore } from "../stores/data";

// layouts

// website pages
import Landing from "@/www/pages/landing/index.vue";
import Maisonette from "@/www/pages/landing/maisonette/index.vue";
const routes = [
  /*---- website routes  ----*/
  {
    path: "/",
    name: "Landing",
    component: Landing,
  },
  {
    path: "/maisonette",
    name: "Maisonette",
    component: Maisonette,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0, behavior: "smooth" };
    }
  },
});

router.beforeEach((to, from, next) => {
  // ✅ This will work because the router starts its navigation after
  // the router is installed and pinia will be installed too
  const store = useDataStore();

  if (to.meta.requiresAuth && !store.isLoggedIn) {
    next("/login");
  } else {
    next();
  }
});

export default router;
