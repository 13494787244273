import Button from "../components/global/Button";
import ButtonNew from "../components/global/ButtonNew";
import TextInput from "../components/global/TextInput";
import TextArea from "../components/global/TextArea";
import OTPInput from "../components/global/OTPInput";
import Container from "../components/global/Container";
import SelectInput from "../components/global/SelectInput";
import SelectInput2 from "../components/global/SelectInput2";
import MultiSelect from "../components/global/MultiSelect";
import DeductionSelect from "../components/global/DeductionSelect";
import CheckBoxInput from "../components/global/CheckBoxInput";
import BankSelect from "../components/global/BankSelect";
import Modal from "../components/global/Modal";
import SuccessModal from "../components/global/SuccessModal";
import EligibilityModal from "../components/global/EligibilityModal";
import WarningModal from "../components/global/WarningModal";
import PreviewModal from "../components/global/PreviewModal";
import LoadingModal from "../components/global/LoadingModal";
import BackButton from "../components/global/BackButton";
import EmployeeTable from "../components/global/EmployeeTable";
import MobilePayrollTable from "../components/global/MobilePayrollTable";
import BonusTable from "../components/global/BonusTable";
import Pagination from "../components/global/Pagination";
import EmployeeSelect from "../components/global/EmployeeSelect";
import EmployeeBonusSelection from "../components/global/EmployeeBonusSelection";
import BonusSelection from "../components/global/BonusSelection";
import ProviderSelect from "../components/global/ProviderSelect";
import Editable from "../components/global/Editable";
import Loader from "../components/global/Loader";
import APIDashboardLoader from "../components/global/APIDashboardLoader";
import RoundLoader from "../components/global/RoundLoader";
import EmptyState from "../components/global/EmptyState";
import DateInput from "../components/global/DateInput";
import VueEasyLightbox from "vue-easy-lightbox";
import EasiCard from "@/components/global/easiCard.vue";
import CustomAlert from "@/components/global/CustomAlert.vue";
import NewAuthLayout from "@/components/global/AuthLayout.vue";
import DisplayCard from "@/components/global/DisplayCard";

export const registerComponents = (app) => {
  app
    .component("easiTable", Editable)
    .component("easiButton", Button)
    .component("easiButtonNew", ButtonNew)
    .component("easiTextInput", TextInput)
    .component("easiTextArea", TextArea)
    .component("easiDate", DateInput)
    .component("easiOtp", OTPInput)
    .component("easiContainer", Container)
    .component("easiSelectInput", SelectInput)
    .component("easiSelectInput2", SelectInput2)
    .component("easiBankSelectInput", BankSelect)
    .component("easiMultiSelectInput", MultiSelect)
    .component("easiDeduction", DeductionSelect)
    .component("easiModal", Modal)
    .component("easiSuccess", SuccessModal)
    .component("easiEligible", EligibilityModal)
    .component("easiWarn", WarningModal)
    .component("easiPreview", PreviewModal)
    .component("easiImageView", VueEasyLightbox)
    .component("easiLoading", LoadingModal)
    .component("easiCheckbox", CheckBoxInput)
    .component("easiBackButton", BackButton)
    .component("easiEmployeeTable", EmployeeTable)
    .component("MobilePayrollTable", MobilePayrollTable)
    .component("easiBonusTable", BonusTable)
    .component("easiPagin", Pagination)
    .component("easiEmployeeSelect", EmployeeSelect)
    .component("easiEmployeeBonus", EmployeeBonusSelection)
    .component("bonusSelection", BonusSelection)
    .component("easiProviderSelect", ProviderSelect)
    .component("easiBackButton", BackButton)
    .component("easiLoader", Loader)
    .component("APIDashboardLoader", APIDashboardLoader)
    .component("easiRoundLoader", RoundLoader)
    .component("easiEmptyState", EmptyState)
    .component("easiCard", EasiCard)
    .component("easiAlert", CustomAlert)
    .component("easiLayout", NewAuthLayout)
    .component("easiTextCard", DisplayCard);
};
