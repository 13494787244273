<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.83333 15.1667H6.83333V10.3333H6.19999C5.81333 10.3333 5.5 10.02 5.5 9.63334V8.36666C5.5 7.97999 5.81333 7.66667 6.19999 7.66667H6.83333V6C6.83333 4.62 7.95333 3.5 9.33333 3.5H11.1333C11.52 3.5 11.8333 3.81333 11.8333 4.19999V5.80001C11.8333 6.18667 11.52 6.5 11.1333 6.5H9.83333V7.66667H11.0867C11.3 7.66667 11.4933 7.76 11.6267 7.92C11.76 8.08 11.8133 8.29333 11.7733 8.5L11.52 9.76666C11.4533 10.0933 11.1667 10.3267 10.8333 10.3267H9.83333V15.1667V15.1667ZM7.83333 14.1667H8.83333V9.33333H10.5933L10.7267 8.66667H8.84001V6.19999C8.84001 5.81333 9.15333 5.5 9.54 5.5H10.84V4.5H9.33333C8.50667 4.5 7.83333 5.17333 7.83333 6V8.66667H6.5V9.33333H7.83333V14.1667Z"
      fill="white"
    />
    <path
      d="M9.99992 15.1668H5.99992C2.37992 15.1668 0.833252 13.6202 0.833252 10.0002V6.00016C0.833252 2.38016 2.37992 0.833496 5.99992 0.833496H9.99992C13.6199 0.833496 15.1666 2.38016 15.1666 6.00016V10.0002C15.1666 13.6202 13.6199 15.1668 9.99992 15.1668ZM5.99992 1.8335C2.92659 1.8335 1.83325 2.92683 1.83325 6.00016V10.0002C1.83325 13.0735 2.92659 14.1668 5.99992 14.1668H9.99992C13.0733 14.1668 14.1666 13.0735 14.1666 10.0002V6.00016C14.1666 2.92683 13.0733 1.8335 9.99992 1.8335H5.99992Z"
      fill="white"
    />
  </svg>
</template>
