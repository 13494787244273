<template>
  <section
    id="maisonettepricing-section"
    class="how-it-works py-16 lg:py-16 relative"
  >
    <div class="l-container">
      <div
        class="grid grid-cols-1 md:grid-cols-1 gap-y-4 md:gap-y-0 items-start md:mx-auto bg-white rounded-3xl p-5"
      >
        <div class="flex justify-center media md:-ml-7">
          <!-- <img src="./assets/plan-img.png" class="inline-block" /> -->
          <img src="./assets/maisonette-flyer.webp" class="inline-block" />
        </div>

        <!-- <div
          class="bg-white border border-newAsh rounded-3xl flex flex-col gap-4 pt-5 h-full"
        >
          <div class="flex items-center md:justify-center gap-2 px-2 md:gap-4">
            <button
              v-for="i in steps"
              :key="i.title"
              @click="setStep(i)"
              class="focus:outline-none rounded-full px-4 py-3 w-32 text-xs md:text-sm text-center capitalize"
              :class="
                selectedStep.title === i.title
                  ? 'bg-dark-800 text-white'
                  : 'bg-newGrey text-dark-800'
              "
            >
              {{ i.title }}
            </button>
          </div>

          <div
            style="background-color: #f8f8f8"
            class="flex flex-col gap-6 p-5 rounded-bl-3xl h-full rounded-br-3xl"
          >
            <div class="flex justify-between items-center">
              <h3 class="text-primary-deep font-bold capitalize text-lg">
                Standard
                <span class="text-dark-800 text-sm font-medium"
                  >({{ selectedStep.sqm }})</span
                >
              </h3>

              <div
                class="bg-mint p-2 text-primary font-bold md:text-lg w-auto text-center"
                style="border-radius: 12px"
              >
                {{ selectedStep.amount }}
              </div>
            </div>

          

            <div class="flex items-center gap-3">
              <div
                class="bg-white p-2 text-dark-800 text-xs w-32 text-center"
                style="border-radius: 8px"
              >
                Receipt of payment
              </div>
              <div
                class="bg-white p-2 text-dark-800 text-xs w-32 text-center"
                style="border-radius: 8px"
              >
                Deed of assignment
              </div>
            </div>

            <div class="flex items-center gap-3">
              <div
                class="bg-white p-2 text-dark-800 text-xs w-32 text-center"
                style="border-radius: 8px"
              >
                Allocation
              </div>

              <div
                class="bg-white p-2 text-dark-800 text-xs w-32 text-center"
                style="border-radius: 8px"
              >
                Contract of sales
              </div>
            </div>

            <div class="mt-6 flex flex-col gap-3">
              <div class="flex justify-between items-center">
                <h2 class="text-primary-deep font-bold capitalize text-base">
                  Other payment
                </h2>

                <div
                  class="bg-mint p-2 text-primary font-bold md:text-lg w-auto text-center"
                  style="border-radius: 12px"
                >
                  {{ formatAmount(4000000) }}
                </div>
              </div>

              <div class="flex items-center gap-3">
                <div
                  class="bg-white p-2 text-dark-800 text-xs w-32 text-center"
                  style="border-radius: 8px"
                >
                  Developmental fee
                </div>
                <div
                  class="bg-white p-2 text-dark-800 text-xs w-32 text-center"
                  style="border-radius: 8px"
                >
                  Documentation fee
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <div class="text-center mb-8 lg:mb-16">
        <!-- <h2 class="font-bold text-primary-deep text-2xl mb-4">
          Our unique offers
        </h2>
        <p class="section-text text-center">
          Select the plan that suits your needs. Make a one-time payment or opt
          for a <br />
          payment plan spread over 3, 6, or 12 months.
        </p> -->
        <SiteButton @click="$emit('book', 'inspect')" class="mt-7">
          <span> Book Inspection</span>
          <span class="inline-block -mb-1 ml-3"
            ><svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.83337 10.7891H14.1667"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.8334 7.45572L14.1667 10.7891L10.8334 14.1224"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
        </SiteButton>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import step1gif from "./assets/onbrdng.gif";

import SiteButton from "@/www/components/SiteButton.vue";
import { helperFunctions } from "@/composable/helperFunctions";

const { formatAmount } = helperFunctions;

const emit = defineEmits(["book"]);
let steps = ref([
  {
    title: "3 Months",
    sqm: "450 sqm",
    desc: "3 Months",
    amount: formatAmount(9000000),
  },
  {
    title: "6 Months",
    sqm: "450 sqm",
    desc: "6 Months",
    amount: formatAmount(9500000),
  },
  {
    title: "12 Months",
    sqm: "450 sqm",
    desc: "12 Months",
    amount: formatAmount(10000000),
  },
]);

const selectedStep = ref({});
selectedStep.value = steps.value[0];

const setStep = (obj) => {
  selectedStep.value = obj;
};
</script>

<style lang="scss" scoped>
.how-it-works {
  .media img {
    max-height: 500px;
    max-width: 100%;
  }

  .heading {
    color: #292a29;

    span {
      color: #ea4e4b;
    }
  }

  @screen lg {
    .media img {
      max-width: 500px;
    }
  }
  .heading {
    color: #11453b;
  }

  .video {
    height: 300px;
  }

  .steps-mobile {
    .media {
      display: flex;
      justify-content: center;
    }

    .media-img {
      max-height: 400px;
      border-radius: 40.7687px;
    }

    .step-title {
      color: #11453b;
    }
  }

  .step-indicators {
    .dot {
      @apply w-2 h-2 rounded-full;
      background: #d9ebcd;
      transition: 0.24s ease;

      &.active {
        @apply w-6 bg-primary;
      }
    }
  }

  .onboarding-video {
    height: 600px;
    width: 600px;
    position: relative;
    display: flex;
    justify-content: center;

    img {
      height: 100%;
      width: auto;
      border-radius: 40.7687px;
    }

    .onboarding-video-frame {
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: grid;
      place-items: center;

      .circle {
        border-radius: 100%;
        display: grid;
        place-items: center;
      }

      .circle-1 {
        background: #f0f7eb;
        border: 0.186592px solid #11453b;
        width: 500px;
        height: 500px;
      }

      .circle-2 {
        background: #d9ebcd;

        width: 85%;
        height: 90%;
      }

      .circle-3 {
        background-color: #11453b;
        width: 100%;
        height: 100%;
        width: 90%;
        height: 90%;
      }
    }
  }

  .steps-desktop {
    .steps {
      max-width: 300px;
      display: grid;
    }

    .step-card {
      position: relative;
      display: flex;
      gap: 25px;

      .step-side {
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
      }

      .step-no {
        background: #f2f1f1;
        color: #11453b;
        display: grid;
        place-items: center;
        width: 50px;
        height: 50px;
        border-radius: 9px;
        font-size: 28px;
        line-height: 48px;
        font-weight: bold;
        cursor: pointer;
        user-select: none;
        transition: 0.28s ease;
        transition-delay: 0.2s;
        flex-shrink: 0;

        &:hover {
          box-shadow: 0px 9px 18px rgba(170, 170, 170, 0.15);
        }
      }

      .step-line {
        width: 1.2px;
        flex: 0;
        background: #11453b;
        align-self: center;
        margin-top: 10px;
        margin-bottom: 10px;
      }

      .step-content {
        padding-bottom: 62px;
      }

      h3 {
        font-size: 20px;
        font-weight: bold;
        line-height: 24px;
        margin-bottom: 14px;
      }

      p {
        color: #515251;
      }

      &.active {
        .step-no {
          background-color: #11453b;
          color: white;
          box-shadow: 0px 20px 48px rgba(170, 170, 170, 0.29);
        }

        .step-line {
          flex: 1;
          transition-timing-function: ease;
          transition-duration: var(--step-duration, 0.2s);
        }
      }

      &.completed {
        .step-line {
          flex: 1;
        }
      }
    }

    .step-card-3 {
      padding-bottom: 0;
    }

    svg.step-line {
      display: none;
    }

    .step-line-1 {
      position: absolute;
      left: -50px;
      top: 30px;
      height: 100%;
    }

    .step-line-2 {
      position: absolute;
      height: 105%;
      top: 25px;
      bottom: -30px;
      left: 40px;
    }
  }

  @screen lg {
  }
}
</style>
