<template>
  <div
    :class="noBg ? 'bg-none' : bg ? bg : 'bg-white' + ' ' + paddingHorizontal"
    class="rounded-3xl relative py-6 max-w-full"
    v-bind="$attrs"
  >
    <div
      v-if="showBackbutton"
      class="absolute top-8 left-5 cursor-pointer flex"
    >
      <span @click="closeFN" class="cursor-pointer">
        <img src="@/assets/icons/IconBack.svg" alt="" />
      </span>
    </div>
    <span
      v-if="isClose && !noBg && !hideCancel"
      @click="closeFN"
      class="absolute top-5 right-5 cursor-pointer"
    >
      <img src="@/assets/icons/close.svg" alt="" />
    </span>
    <slot />
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
};
</script>

<script setup>
const props = defineProps({
  isClose: {
    type: Boolean,
    default: true,
  },
  bg: {
    type: String,
    default: "",
  },
  noBg: {
    type: Boolean,
    default: false,
  },
  showBackbutton: {
    type: Boolean,
    default: false,
  },
  hideCancel: {
    type: Boolean,
    default: false,
  },
  paddingHorizontal: {
    type: String,
    default: "  sm:px-16 lg:px-20 xl:px-24 ",
  },
});
const emit = defineEmits(["close"]);

function closeFN() {
  emit("close");
}
</script>

<style></style>
