<template>
  <footer
    id="footer-section"
    class="site-footer py-12"
    style="background-color: #001d13"
  >
    <div class="l-container mt-16">
      <h2 class="text-white font-bold text-center text-2xl">
        Frequently Asked Questions
      </h2>
      <div class="flex flex-col lg:justify-between gap-4">
        <!-- <div
          class="footer-cols lg:w-6/12 flex flex-wrap justify-center lg:justify-start lg:flex-nowrap gap-y-8 lg:gap-10"
        >
          <div
            class="footer-col w-1/2 lg:w-1/4 products text-center lg:text-left"
          >
            <h3 class="col-heading">Products</h3>
            <ul class="footer-list">
              <li>
                <a href="/individual"> Individual </a>
              </li>
              <li>
                <a href="/business">Businesses</a>
              </li>
            </ul>
          </div>
          <div
            class="footer-col order-3 lg:order-none w-1/2 lg:w-1/4 legal text-center lg:text-left"
          >
            <h3 class="col-heading">Legal</h3>
            <ul class="footer-list">
              <li>
                <a href="/privacy-policy">Privacy Policy</a>
              </li>
              <li>
                <a href="/terms-of-use"> Terms of Use </a>
              </li>
            </ul>
          </div>

          <div class="footer-col lg:w-2/4 contact-us hidden lg:block text-left">
            <h3 class="col-heading">Contact Us</h3>
            <ul class="grid gap-2 mb-6 footer-list">
              <li>
                <a href="mailto:hi@myeazipay.com">hi@myeazipay.com</a>
              </li>
              <li>
                <a href="tel:+234 913 476 6185">+234 913 476 6185</a>
              </li>
            </ul>

            <FooterContactForm />
          </div>
        </div> -->

        <Accordion />
      </div>

      <div class="mt-24 flex flex-col gap-7 items-center">
        <h3 class="text-white font-bold text-lg">TopAce</h3>

        <ul
          class="nav-links flex text-center text-white justify-center text-sm items-center gap-6"
        >
          <li>
            <a class="nav-link" href="#features-section">Features</a>
          </li>
          <li>
            <a class="nav-link" href="#gallery-section">Gallery</a>
          </li>
          <li>
            <a class="nav-link" href="#pricing-section">Pricing</a>
          </li>
          <li>
            <a class="nav-link" href="#footer-section">FAQs</a>
          </li>
          <li>
            <a class="nav-link" href="#footer-section">Contact us</a>
          </li>
        </ul>
      </div>

      <hr class="bg-white mt-10" />

      <!-- Social icons -->
      <div
        class="mt-10 w-full text-center flex md:flex-row flex-col gap-y-4 md:justify-between lg:text-left flex-shrink-0"
      >
        <p class="mb-2 text-white text-sm">
          © 2024 TopAce. All rights reserved
        </p>
        <p class="text-white">+234 916 548 7353.</p>
        <div class="social-links inline-flex gap-3 justify-center">
          <a
            v-for="(link, index) in socialLinks"
            :key="`social-link-${index}`"
            :href="link.href"
            :title="link.title"
            target="_blank"
            rel="noopener noreferrer"
            class="social-link w-7 h-7 rounded-full bg-primary flex items-center justify-center"
          >
            <component :is="link.icon" />
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
import Logo from "./Logo.vue";
import FacebookIcon from "./social-icons/Facebook.vue";
import TwitterIcon from "./social-icons/Twitter.vue";
import LinkedinIcon from "./social-icons/Linkedin.vue";
import InstagramIcon from "./social-icons/Instagram.vue";
import WhatsappIcon from "./social-icons/Whatsapp.vue";
import FooterContactForm from "./FooterContactForm.vue";
import Accordion from "./Accordion.vue";

const socialLinks = [
  {
    title: "Instagram",
    href: "",
    icon: InstagramIcon,
  },
  {
    title: "Twitter",
    href: "",
    icon: TwitterIcon,
  },
  {
    title: "Whatsapp",
    href: "",
    icon: WhatsappIcon,
  },
  {
    title: "Facebook",
    href: "",
    icon: FacebookIcon,
  },
];
</script>

<style lang="scss" scoped>
.site-footer {
  border-top: 1px solid #eaeaea;

  .footer-cols {
  }

  .col-heading {
    font-weight: bold;
    margin-bottom: 16px;
    font-size: 20px;
    line-height: 24px;
  }

  .footer-list {
    display: grid;
    gap: 12px;

    li {
      color: #7c7c7c;
      font-size: 16px;
      line-height: 24px;
    }

    a:hover {
      color: #ea4e4b;
    }
  }
}
</style>
