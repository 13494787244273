<template>
  <main>
    <div
      @click.self="close"
      style="
        z-index: 300;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: #032821;
        opacity: 0.95;
      "
      class="inset-0 w-full -z-5 overflow-y-auto py-16"
    >
      <div
        class="md:w-1/4 w-11/12 z-20 mx-auto bg-white rounded-2xl overflow-y-auto shadow-xs"
      >
        <div class="flex flex-col mt-6 items-center">
          <img class="w-24" :src="ineligible" alt="" />
        </div>
        <div
          class="w-full text-cancelRed font-extrabold text-center flex justify-center items-center p-6 pb-2 text-xl"
        >
          {{
            headerMessage && headerMessage.length
              ? headerMessage
              : "Oops! Not Eligible"
          }}
        </div>

        <main class="flex flex-col gap-7 mb-7 items-center">
          <div
            class="px-0 text-xs font-bold text-center mx-8 mt-3 flex flex-col items-center justify-center text-dark-800"
          >
            <slot name="message" />
          </div>

          <div class="w-full justify-center">
            <slot name="action" />
          </div>
        </main>
      </div>
    </div>
  </main>
</template>

<script setup>
import ineligible from "@/assets/icons/empty-state.svg";
const emit = defineEmits(["update"]);
const props = defineProps({
  headerMessage: {
    type: String,
    default: "",
  },
});
function close() {
  emit("close");
  window.location.reload();
}
</script>

<style></style>
